<template>
  <div class="lotteryContent">
    <div class="step1" v-if="lotterystep == 1">
      <p>
        10月30日，陽子將會做出決定。那一天，小鎮只會剩下黑夜。<br /><br /><span
          style="color: #ff7d7d"
          >「人們恐懼結果，神，則害怕原因。」</span
        ><br />你要選擇面對結果，還是試著找出原因？<br /><br />謝謝你完成了六個關卡並找到秘密的重要線索。<br />為了感謝你的幫助，請留下你的連繫資訊，你將有機會成為<span
          style="color: #ffb359"
          >「天選之人」</span
        >，<br />在<span style="color: #ffb359">10月30日</span
        >那天造訪小鎮，<span style="color: #ffb359"
          >「親身」目睹故事結局。</span
        >
      </p>
    </div>
    <div class="step2" v-if="lotterystep == 2">
      <div class="form">
        <div
          class="inputWrap"
          v-for="(item, index) in form"
          :key="index"
          :class="[item.label]"
        >
          <label :for="item.label">{{ item.labelText }}</label>
          <input :id="item.label" type="text" v-model="item.data" />
        </div>
      </div>
      <p>
        *本網站所取得使用者個人資料，僅供於活動報名依據及主辦單位連繫使用，並不做其他用途。
      </p>
      <p>*主辦單位保留所有活動異動權與解釋權。</p>
      <p>*按下送出即同意以上聲明。</p>
    </div>
    <div class="step3" v-if="success">
      <p>天神已收到你的資料，在抽出天選之人以前，以下幾點說明要請你留意</p>
      <p>
        1. 本活動將於<span class="orange">10月24日(日)23:59截止登錄</span
        >，並於<span class="orange">10月26日(二)12:00以電子郵件通知中獎者</span
        >，敬請留意您的電子信箱。
      </p>
      <p>
        2. 活動電子票卷將以<span class="orange">OPENTIX APP</span
        >發送及領取，建議先行下載APP並註冊OPENTIX
        會員以利後續索票流程。註冊教學<span class="blue"
          ><a href="https://bit.ly/39AqBOn" target="_blank">請點我</a></span
        >。
      </p>
      <p>
        3. 票卷連結效期為<span class="orange">24小時</span
        >，逾期領取票卷者，視同自動放棄本活動中獎權益，主辦單位將不另行通知。
      </p>
      <p>
        4.
        兩廳院保有修正、變更、取消、暫停本申請辦法部分或全部之權利，如有未盡事宜，由本場館修正補充之，並隨時於官網公告。
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: "lotteryContent",
  data: () => ({
    form: [
      {
        labelText: "姓名",
        data: "",
        label: "name",
      },
      {
        labelText: "電話號碼",
        data: "",
        label: "phone",
      },
      {
        labelText: "電子信箱",
        data: "",
        label: "email",
      },
    ],
  }),
  props: {
    lotterystep: {
      default: 1,
    },
    success: {
      default: false,
    },
    submitStatus: {
      default: false,
    },
  },
  watch: {
    async submitStatus() {
      if (
        this.form[0].data == "" ||
        this.form[1].data == "" ||
        this.form[2].data == ""
      ) {
        if (this.submitStatus) {
          alert("* 還有資料沒有填寫唷! *");
        }
        this.submitStatus = false;

        this.$emit("lotteryFailed");
      }

      if (this.submitStatus) {
        let url = "https://bkhole.app/";
        let obj = {
          name: this.form[0].data,
          phone: this.form[1].data,
          mail: this.form[2].data,
        };
        let lottery = (param) =>
          this.$http.post(`${url}town-without-gods`, param);
        let res = await lottery(obj);
        console.log(res);
        if (res.status == 200) {
          this.$emit("lotterySuccess");
        } else {
          this.$emit("lotteryFailed");
        }
      }
    },
  },
  beforeMount() {
    this.$emit("initLottery");
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_media.scss";

.lotteryContent {
  .blue {
    color: #278bff;
  }

  .orange {
    color: #ffb359;
  }

  .step2 {
    .form {
      width: 80%;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-top: 6%;
      margin-bottom: 3%;

      @include multiBreakpoint(tabletS, phone) {
        padding-top: 1%;
      }

      .inputWrap {
        width: 100%;

        label {
          width: 100%;
          font-size: 21px;

          @include multiBreakpoint(tabletS, phone) {
            font-size: 15px;
          }
        }

        input {
          width: 100%;
          background-color: #c0e3ff;
          line-height: 1.5;
          font-size: 20px;
          padding: 0 5px;
          outline: none;
          border-radius: 5px;
          border: none;

          @include multiBreakpoint(tabletS, phone) {
            font-size: 15px;
          }
        }
      }

      .inputWrap.name,
      .inputWrap.phone {
        width: 47%;
        margin-bottom: 5%;
      }
    }

    p {
      text-align: center;
      color: #b4c8e6;
      font-size: 18px;

      @include multiBreakpoint(tabletS, phone) {
        font-size: 13px;
      }
    }
  }
}
</style>