<template>
    <div class="chatbox">
        <div class="container" :class="[name]">
            <div class="main">
                <img :src="avatarImg" class="avatar" alt="" draggable="false">
                <div class="text">
                    <p>{{popData[name].name[chatStep]}}</p>
                    <vue-typer :text='popData[name].text[chatStep]' :repeat='0'></vue-typer>
                </div>
            </div>
        </div>    
        <div class="btnWrap">
            <div class="btnOne btn" v-visible="popData[name].btn.one[chatStep]!=''" @click="playgame">
                <img src="@/assets/img/chat/btn.svg" alt="" draggable="false">
                <img src="@/assets/img/chat/btn_press.svg" alt="" draggable="false">
                <span>{{popData[name].btn.one[chatStep]}}</span>
            </div>
            <div class="btnTwo btn" @click="nextStep">
                <img src="@/assets/img/chat/btn.svg" alt="" draggable="false">
                <img src="@/assets/img/chat/btn_press.svg" alt="" draggable="false">
                <span>{{popData[name].btn.two[chatStep]}}</span>
            </div>
        </div>
    </div>
</template>
<script>
import { VueTyper } from 'vue-typer'
export default {
    name:'chatbox',
    data:()=>({
        popData:{
            sunny:{
                name:['陽子','陽子','陽子'],
                text:['對，你沒認錯，我是陽子。我現在哭得很醜不想合照.....',
                'Lucy的歌很好聽吧，她是我好姊妹，是鎮上最厲害的歌手！我只是個蠢Model。',
                '哈哈哈，一切都無所謂啦，毀掉就算了，喝到斷片，就可以把痛苦給忘了。過來過來，沒喝醉不准走！'],
                btn:{
                    one:['','','我超會喝'],
                    two:['你還好嗎？','你喝醉了嗎','不太方便']
                },
                img:['sunny','sunny','sunny']
            },
            run:{
                name:['RUN','RUN','RUN'],
                text:['我們是「就怕你不來—軟式兄弟」，敢不敢做點好玩的？啊？還有人不知道我，太讚了，竟然遇到沒網路的原始人',
                '我是RUN，他是天屎，你先跟我們合照一波，之後會增值，我說真的。','都來到森林這裡了，參加我們外景節目的試膽任務吧！'],
                btn:{
                    one:['','','好吧我試試'],
                    two:['你是誰？','呃...謝謝','我有事先走']
                },
                img:['run','run','run']
            },
            chenwang:{
                name:['我','曾建國','萬寶路','曾建國'],
                text:['咦？他們好像在談論什麼......',
                '那「禮物」我就收下啦。不過，萬寶路，你辦事最好不要出什麼紕漏，我當市長可不是來替你擦屁股的。',
                '您別擔心，我兄弟口風很緊，做事牢靠，這筆生意談成，該再給您的不會少。',
                '你？新來的助理嗎？還愣在那幹嘛，還不快去辦公室把東西拿來。'],
                btn:{
                    one:['','','','去找找看'],
                    two:['繼續偷聽','聽下去','聽下去','你認錯人']
                },
                img:['chenwang','wansui','chenwang']
            },
            green:{
                name:['小綠','小綠','我'],
                text:['小綠，清醒一點，你是小綠，世界上最美的人。',
                '你不覺得醜陋的東西就應該消失嗎？我把這面牆變得更美了。大家應該要感謝我才對。',
                '牆上都是奇怪的塗鴉，這樣好嗎...'],
                btn:{
                    one:['','','幫忙清理'],
                    two:['打招呼','看看牆面','不甘我的事']
                },
                img:['green','green']
            },
            pepe:{
                name:['沛沛','沛沛'],
                text:['各位沛沛豬久等了♡ 今天沛沛開播會有特別活動喔！',
                '因為啊，最近人家有點窮，需要大家多刷一點禮物......沛沛豬們可以來幫幫我嗎♡',],
                btn:{
                    one:['','義不容辭'],
                    two:['好可愛','我沒有錢']
                },
                img:['pepe','pepe']
            },
            cometlady:{
                name:['彗星夫人','彗星夫人'],
                text:['你，就是你。我都看見了。你想繼續逃避過往，還是決定尋找未來？',
                '我是彗星夫人，我有能力可以改變你的命運。',],
                btn:{
                    one:['','我要改運'],
                    two:['什麼意思','怪力亂神']
                },
                img:['cometlady','cometlady']
            },
            nightMarket:{
                name:['肉舖老闆'],
                text:['來喔來喔！想買新鮮的肉就來找我吧！最近進了一批不錯的肉呢～'],
                btn:{
                    one:[''],
                    two:['好']
                },
                img:['nightMarket']
            },
            salon:{
                name:['理髮師'],
                text:['別看我有點年紀，鎮上年輕人的造型都是來我這裡弄的！'],
                btn:{
                    one:[''],
                    two:['好']
                },
                img:['salon']
            },
            police:{
                name:['警察'],
                text:['我們小鎮治安很好，任何壞人都逃不過警察們的法眼！'],
                btn:{
                    one:[''],
                    two:['好']
                },
                img:['police']
            },
            reporter:{
                name:['記者','記者','記者'],
                text:['「就怕你不來—軟式兄弟」有陽子的那一集直接破百萬收看，全都靠黑粉支持。','每個人為了紅都不擇手段啊。','快選舉了，市長感覺也有些佈局呢。'],
                btn:{
                    one:['','',''],
                    two:['咦？','......','這樣啊...']
                },
                img:['reporter','reporter','reporter']
            },
            accusefive:{
                name:['告五人','告五人'],
                text:['快點快點，我們的表演快要趕不上了！','你也想來聽我們的演出嗎？等等來酒吧看看吧！'],
                btn:{
                    one:['',''],
                    two:['哪裡有表演','太讚ㄌ']
                },
                img:['accusefive','accusefive']
            },
            recycle:{
                name:['清潔阿伯'],
                text:['那隻貓有主人，一個小女生，聽說是市長的女兒......她不知怎麼了，都躲在這裡哭呢'],
                btn:{
                    one:[''],
                    two:['幫哭哭']
                },
                img:['recycle']
            },
        },
        chatStep:0,
        skinAvatar:['skin1','skin2','skin3']
    }),
    props:{
        name:{
            default:'chenwang'
        },
        skin:{
            default:1
        }
    },
    created(){
        let idx = 0;
        if(this.skin=="p1"){idx=0;}
        else if(this.skin=="p3"){idx=1;}
        else if(this.skin=="p2"){idx=2;}
        if(this.popData.green.img.length<3){
            this.popData.green.img.push(this.skinAvatar[idx])
            this.popData.chenwang.img.unshift(this.skinAvatar[idx]);
        }   
    },
    components:{VueTyper},
    computed:{
        avatarImg(){
            return `${require(`@/assets/img/chat/avatar/${this.popData[this.name].img[this.chatStep]}.svg`)}`
        },
        totalStep(){
            return this.popData[this.name].text.length
        }
    },
    methods:{
        nextStep(){
            if(this.chatStep+1 < this.totalStep){
                this.chatStep++; 
            }else{
                this.$emit('closeChat')
                this.chatStep = 0;
            }
        },
        playgame(){
            this.$emit('playgame',this.name);
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variable.scss";
@import "@/assets/scss/_media.scss";

.chatbox{
    position:fixed;
    bottom:5%;
    width: 40%;
    @include absXcenter;
    display: flex;
    justify-content: space-between;
    width:715px;
    @include multiBreakpoint(tabletS,phone){
        width:415px;
    }
    .container{
        background-size: 100%;
        background-image:url('~@/assets/img/chat/chatBox.png');
        background-repeat: no-repeat;
        //padding: 4%;
        //width:570px;
        position:relative;
        padding-bottom:21%;
        padding-bottom: 124px;
        width: 78%;
        @include multiBreakpoint(tabletS,phone){
            padding-bottom: 71px;
        }
        .main{
            position:absolute;
            width:100%;
            padding:0 4%;
            display: flex;
            box-sizing: border-box;
            @include absYcenter;
            .avatar{
                width: 14.5%;
                margin-right: 2%;
                align-self: flex-start;
            }
            .text{
                width:85.5%;
                p{
                    font-size:26px;
                    color:#1850B7;
                    @include multiBreakpoint(tabletS,phone){
                        font-size: 17px;
                    }
                }
            }
            .vue-typer{
                font-size:26px;
                @include multiBreakpoint(tabletS,phone){
                    font-size: 17px;
                }
            }
        }
        // &.sunny{
        //     .main{
        //         .vue-typer{
        //             ::v-deep .custom.char.typed{
        //                 &:nth-of-type(24){
        //                     padding-right:12%;
        //                 }
        //             }
        //         }
        //     }
        // }
    }
    .btnWrap{
        width: 20%;
        display: flex;
        flex-direction: column;
        padding-bottom: 0.6%;
        justify-content: space-between;
        > div{
            position:relative;
            img{
                &:nth-of-type(2){
                    display: none;
                }
            }
            &:hover{
                img{
                    &:nth-of-type(1){
                        display: none;
                    }
                    &:nth-of-type(2){
                        display: block;
                    }
                }
            }
            span{
                position:absolute;
                @include abscenter;
                color:#0572A4;
                top:60%;
                font-size: 25px;
                width: 100%;
                text-align: center;
                @include multiBreakpoint(tabletS,phone){
                    font-size:17px;
                }
            }

        }
    }
}
</style>