<template>
    <div class="loadingBar">
        <p>LOADING<span class="dot" v-for="(item,index) in 3" :key="index">.</span></p>
        <div class="bar">
            <div class="process" :style="widthTotal"></div>
            <img src="@/assets/img/cover/outLine.svg" alt="" draggable="false">
        </div>
    </div>
</template>
<script>
export default {
    name:'loadingBar',
    props:{
        percentTotal:{
            default:100
        },
        percentNow:{
            default:0
        }
    },
    computed:{
        widthTotal(){
            return {width:`calc(${this.percentNow/this.percentTotal*100}% - 6px)`}
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_media.scss";
.loadingBar{
    p{
        font-size: 30px;
        text-align: center;
        color:#fff;
        span{
            opacity: 0;
            transition-property: all; 
            transition-timing-function:ease;
            animation-iteration-count:infinite;
            animation-duration:1s;
            animation-timing-function:ease-in;
            &:nth-of-type(1){
                animation-name:dot;
            }
            &:nth-of-type(2){
                animation-name:dot2;
            }
            &:nth-of-type(3){
                animation-name:dot3;
            }
        }
    }
    @keyframes dot{
        0%{
            opacity: 0;
        }
        33%{
            opacity: 100%;
        }
        100%{
            opacity: 100%;
        }
    }
    @keyframes dot2{
        0%{
            opacity: 0;
        }
        33%{
            opacity: 0;
        }
        66%{
            opacity: 100%;
        }
        100%{
            opacity: 100%;
        }
    }
    @keyframes dot3{
        0%{
            opacity: 0;
        }
        66%{
            opacity: 0;
        }
        100%{
            opacity: 100%;
        }
    }

    .bar{
        position:relative;
        width: 576px;
        @include multiBreakpoint(tabletS,phone){
            width:376px;
        }
        .process{
            background-color: #ffc878;
            height: calc(100% - 6px);
            position: absolute;
            top: 3px;
            left: 3px;
            width: calc(100% - 6px);
            transition-timing-function:ease;
            transition-property: all; 
            transition-duration: 2s;
            @include multiBreakpoint(tabletS,phone){
                height: calc(100% - 4px);
            }
            &:before{
                content: '';
                height: 14%;
                display: inline-block;
                width: 100%;
                position: absolute;
                top: 26%;
                background-color: #ffebd3;
            }
            &:after{
                content: '';
                height: 33%;
                width: 100%;
                display: inline-block;
                bottom: 0;
                position: absolute;
                background-color: #ff9f44;
            }
        }
        img{
            position:relative;
        }
    }
}
</style>