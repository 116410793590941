<template>
    <div class="collectionend">
        <img src="@/assets/img/pop/collectEnd.svg" draggable="false" alt="">
        <p>恭喜你收集完所有物品，接下來請將這些送到<span style="color:#FFB359">小鎮廣場</span>給天神吧！</p>
        <div
            class="button btn"
            @click="closePop"
        >
            <img src="@/assets/img/pop/btn_sent.svg" alt="" draggable="false" />
        </div>
    </div>
</template>
<script>
export default {
    name:'CollectionEnd',
    methods:{
        closePop(){
            this.$emit('closePop');
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variable.scss";
@import "@/assets/scss/_media.scss";
.collectionend{
    position:absolute;
    top:28%;
    width: 66%;
    left:50%;
    transform:translateX(-50%);
    height: 62%;
    >img{
        margin-bottom:3%;
    }
    p {
        //font-size: 1.45vw;
        text-align:center;
        font-size: 21px;
        @include multiBreakpoint(tabletS, phone) {
          font-size: 15px;
        }
      }
    .button {
      position: absolute;
      bottom: 0;
      width: 32%;
      @include absXcenter;
    }
}
</style>