import { render, staticRenderFns } from "./DonateGame.vue?vue&type=template&id=0dfa0108&scoped=true&"
import script from "./DonateGame.vue?vue&type=script&lang=js&"
export * from "./DonateGame.vue?vue&type=script&lang=js&"
import style0 from "./DonateGame.vue?vue&type=style&index=0&id=0dfa0108&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0dfa0108",
  null
  
)

export default component.exports