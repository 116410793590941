<template>
    <div class="loadingPage" :style="bgMove">
        <img class="logo" src="@/assets/img/cover/logo.svg" alt="" draggable="false">
        <LoadingBar :percentTotal="percentTotal" :class="{hidden:this.percentNow/this.percentTotal*100==100}" :percentNow="percentNow" />
        <div class="btnWrap" :class="{fadeshow:this.percentNow/this.percentTotal*100 ==100}" @click="closeLoading">
            <img src="@/assets/img/cover/btn_start.svg" alt="" draggable="false">
            <img src="@/assets/img/cover/btn_start_active.svg" alt="" draggable="false">
        </div>
        <!-- <div class="btnWrap" v-if="SHOWEND" :class="{fadeshow:this.percentNow/this.percentTotal*100 ==100,showEndR:SHOWEND}" @click="goUrl">
            <img src="@/assets/img/cover/btn_watch.png" alt="" draggable="false">
            <img src="@/assets/img/cover/btn_watch_active.png" alt="" draggable="false">
        </div> -->
        <img class="logo_s" src="@/assets/img/cover/logo_s.png" alt="" draggable="false">
        <img class="bottom" src="@/assets/img/cover/bottom.png" alt="" draggable="false">
    </div>
</template>
<script>
import LoadingBar from '@/components/loading/LoadingBar.vue'
export default {
    name:'LoadingPage',
    components:{
        LoadingBar
    },
    props:{
        percentTotal:{
            default:100
        },
        percentNow:{
            default:0
        },
        SHOWEND:{
            default:false
        }
    },
    computed:{
        bgMove(){
            return {backgroundPosition:`${100 - this.percentNow/this.percentTotal*100}% bottom`}
        }
    },
    methods:{
        closeLoading(){
            if(this.percentNow/this.percentTotal*100 ==100){
                this.$emit('close');
            }
        },
        goUrl(){
            window.open('https://ntch-lunatictown.com/programs', '_blank');
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variable.scss";
@import "@/assets/scss/_media.scss";
.loadingPage{
    background-image:url(~@/assets/img/cover/homeCover.png);
    background-size:240%;
    //background-position:0% bottom;
    background-color:#3ac8f9;
    background-repeat: no-repeat;
    height:100%;
    width:100%;
    position:fixed;
    top:0;
    left:0;
    transition-timing-function:ease;
    transition-property: all; 
    transition-duration: 2s;
    z-index: 9999999999;
    .logo{
        position:absolute;
        top:20%;
        left:50%;
        transform: translate(-50%,-50%);
        width: 32%;
        width:451px;
        top: 33%;
        @include multiBreakpoint(tabletS){
            width:340px;
        }
        @include multiBreakpoint(tabletS,phone){
            width:240px;
        }
    }
    .logo_s{
        position:absolute;
        left: 20px;
        top: 20px;
        width:15%;
        max-width:294px;
    }
    .bottom{
        position:absolute;
        bottom: 2%;
        width: 22%;
        max-width: 453px;
        @include absXcenter;
    }
    .loadingBar{
        position:absolute;
        top:60%;
        left:50%;
        transform: translate(-50%,-50%);
    }
    .hidden{
        opacity:0;
        // transition-delay: 2s;
        transition:ease-out 2s all;
    }
    .btnWrap{
        width:211px;
        position:absolute;
        top:60%;
        left:50%;
        transform: translate(-50%,-50%);
        opacity:0;
        @include multiBreakpoint(tabletS,phone){
            width:15%;
        }
        img{
            &:nth-of-type(2){
                display: none;
            }
        }
        &:hover,&:focus{
            img{
                &:nth-of-type(1){
                    display: none;
                }
                &:nth-of-type(2){
                    display: block;
                }
            }
        }
    }
    .showEndL{
        left: 41%;
    }
    .showEndR{
        right: 41%;
        transform: translate(50%, -50%);
        left: auto;
    }
    .fadeshow{
        opacity:1;
        transition:all 1s 1s ease-out;
        cursor: pointer;
    }
}
</style>