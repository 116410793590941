<template>
  <div class="cardgame">
    <canvas id="myCanvas" ref="myCanvas"></canvas>
    <PopUp
      v-if="pop.status"
      :PopStep="pop.PopStep"
      @closePop="closePop"
      :gameType="pop.gameType"
      @back="back"
      @again="again"
      @tapping="tapping"
    />
    <GameChat
      @completed="onComplete"
      v-if="popData.status"
      :popData="popData"
      @click="goStepTwo"
      :step="step"
      @submit="submit"
      @chattap="chattap"
    />
  </div>
</template>
<script>
import * as PIXI from "pixi.js";
import { OutlineFilter } from "@pixi/filter-outline";
import { gsap } from "gsap";
import PixiPlugin from "gsap/PixiPlugin";
import PopUp from "@/components/PopUp.vue";
import GameChat from "@/components/chat/GameChat.vue";
import { sound } from "@pixi/sound";
gsap.registerPlugin(PixiPlugin);
PixiPlugin.registerPIXI(PIXI);
export default {
  name: "CardGame",
  data: () => ({
    app: null,
    loader: null,
    centerContainer: null,
    cards: [],
    cardOutline: null,
    background: null,
    cardContainer: null,
    step: 0,
    pop: {
      PopStep: "game",
      gameType: "CardGame",
      status: true,
    },
    popData: {
      status: false,
      avatar: "cometlady",
      name: "彗星夫人",
      text: "",
      gameType: "cardgame",
      result: "",
    },
    keys: {},
    deathCard: null,
    cardsActive: 0,
    cardResult: null,
  }),
  props: {
    tapStatus: {
      default: false,
    },
  },
  mounted() {
    this.setMediaLoad();
    this.$emit("setTapHide");
  },
  components: { PopUp, GameChat },
  methods: {
    closePop() {
      this.pop.status = false;
      this.popData.status = true;
      this.popData.text =
        "請回想一下最近一件令你煩惱的事，並靜下心來抽出一張卡牌。卡牌將告訴你關於這件事的未來...";
    },
    setMediaLoad() {
      this.loader = new PIXI.Loader();
      this.loader.reset();
      this.loader
        .add([
          { name: "bg", url: "./game/cardgame/bg.png" },
          { name: "blueResult", url: "./game/cardgame/blueResult.png" },
          { name: "card_back", url: "./game/cardgame/card_back.png" },
          { name: "card_death", url: "./game/cardgame/card_death.png" },
          { name: "drug", url: "./game/cardgame/drug.png" },
          { name: "feather", url: "./game/cardgame/feather.png" },
          { name: "greenResult", url: "./game/cardgame/greenResult.png" },
          { name: "stone", url: "./game/cardgame/stone.png" },
          { name: "yellowResult", url: "./game/cardgame/yellowResult.png" },
          { name: "close", url: "./game/close.png" },
        ])
        .load(this.init);
    },
    init() {
      this.setCanvas();
      this.setup();
      this.setKeyboard();
    },
    setKeyboard() {
      let vm = this;
      window.addEventListener("keydown", vm.keyDown);
      window.addEventListener("keyup", vm.keyUp);
    },
    goStepTwo() {
      if (this.step == 1) {
        this.step++;
        this.popData.text = "請在以下三個選項中，選擇兩樣珍貴的糖漿材料吧！";
        this.setMaterial();
      }
    },
    chattap() {
      this.goStepTwo();
    },
    keyDown(e) {
      this.keys[e.keyCode] = true;
      if (this.step == 1 && this.keys["32"]) {
        this.goStepTwo();
      }
    },
    keyUp(e) {
      this.keys[e.keyCode] = false;
    },
    setCanvas() {
      let vm = this;
      let myCanvas = this.$refs.myCanvas;
      this.app = new PIXI.Application({
        width: 1920,
        height: 1080,
        antialias: true,
        transparent: false,
        resolution: 2,
        view: myCanvas,
      });
      this.app.renderer.view.style.position = "absolute";
      this.app.renderer.view.style.display = "block";
      this.app.renderer.autoResize = true;
      let screen = this.app.renderer.screen;
      this.app.renderer.resize(window.innerWidth, window.innerHeight);
      this.app.stage.interactive = true;
      this.initRotation = "L";
      if (window.matchMedia("(orientation: portrait)").matches) {
        this.app.renderer.resize(window.innerHeight, window.innerWidth);
        this.windowWidth = window.innerHeight;
        this.windowHeight = window.innerWidth;
      } else {
        this.app.renderer.resize(window.innerWidth, window.innerHeight);
        this.windowWidth = window.innerWidth;
        this.windowHeight = window.innerHeight;
      }
      sound.add("my-sound", "/game/cardgame/music.mp3");
      sound.play("my-sound", { loop: true });
      // if(window.innerWidth<window.innerHeight){
      //     this.app.stage.position.set(screen.width/2, screen.height/2); // center of the screen
      //     this.app.stage.rotation = Math.PI/2;
      //     this.app.stage.pivot.set(screen.width/2, screen.height/2);
      //     this.initRotation = 'O';
      // }
      // window.onresize = function (event){
      //     vm.app.renderer.resize(window.innerWidth, window.innerHeight);
      //     screen = vm.app.renderer.screen;
      //     if(vm.initRotation == 'O'){
      //         if(window.innerWidth>window.innerHeight){
      //             vm.app.stage.pivot.set(screen.height/2,screen.width/2);
      //             vm.app.stage.position.set(screen.width/2, screen.height/2);
      //             vm.app.stage.rotation = 0;
      //         }else{
      //             vm.app.stage.position.set(screen.width/2, screen.height/2);
      //             vm.app.stage.pivot.set(screen.width/2, screen.height/2);
      //             vm.app.stage.rotation = Math.PI/2;
      //         }
      //     }else{
      //         if(window.innerWidth>window.innerHeight){
      //             vm.app.stage.position.set(0,0);
      //             vm.app.stage.pivot.set(0, 0);
      //             vm.app.stage.rotation = 0;
      //         }else{
      //             vm.app.stage.position.set(screen.width/2, screen.height/2);
      //             vm.app.stage.pivot.set(screen.height/2,screen.width/2);
      //             vm.app.stage.rotation = Math.PI/2;
      //         }
      //     }
      //     // 將畫面的正中間放在 app.renderer 一半寬高的位置
      //     // this.app.stage.x = app.renderer.width * 0.5;
      //     // this.app.stage.y = app.renderer.height * 0.5;
      // };
    },
    setup() {
      this.centerContainer = new PIXI.Container();
      this.app.stage.addChild(this.centerContainer);
      this.centerContainer.position.x = this.app.screen.width / 2;
      this.centerContainer.position.y = this.app.screen.height / 2;
      this.background = new PIXI.Sprite(this.loader.resources["bg"].texture);
      this.centerContainer.addChild(this.background);
      this.background.width = (this.app.screen.height / 9) * 16;
      this.background.height = this.app.screen.height;
      this.background.anchor.x = 0.5;
      this.background.anchor.y = 0.5;
      this.setResize();
      this.cardContainer = new PIXI.Container();
      this.app.stage.addChild(this.cardContainer);
      for (let index = 0; index < 10; index++) {
        this.setCard(index, 10);
      }
      this.setClose();
    },
    setResize() {
      this.ratio = this.background.height / this.background.width;
      this.windowRatio = window.innerHeight / window.innerWidth;
      if (this.ratio < this.windowRatio) {
        this.background.width = window.innerWidth;
        this.background.height = this.background.width * this.ratio;
      } else {
        this.background.height = window.innerHeight;
        this.background.width = this.background.height / this.ratio;
      }
    },
    setCard(idx, total) {
      let vm = this;
      let card = new PIXI.Sprite(this.loader.resources["card_back"].texture);
      let globalTransform = this.background.toGlobal(this.app.stage.position);
      let transformX = globalTransform.x - this.background.width / 2;
      let transformY = globalTransform.y - this.background.height / 2;
      let angle = [-15, -12, -9, -6, -3, 0, 6, 9, 12, 15];
      let heightOffset = [55, 40, 25, 15, 0, 0, 15, 25, 40, 55];
      this.cardOutline = [new OutlineFilter(5, 0x4cffff)];
      card.interactive = true;
      card.width = this.background.width * 0.188;
      card.height = this.background.height * 0.475;
      // card.x = this.app.screen.width/7 + transformX + idx*card.width/3;
      // card.y =  this.app.screen.height/6 + card.height/2 + heightOffset[idx];
      //card.x = this.app.screen.width/7 + idx*card.width/3 - card.width/3;
      card.x = ((5 - idx) * card.width) / 3;
      card.anchor.x = 0.5;
      card.anchor.y = 0.5;
      card.y = heightOffset[idx]-100;
      //card.y =  this.app.screen.height/6 + card.height/5 + heightOffset[idx];
      // card.pivot.set(card.width/2,card.height);
      card.buttonMode = true;
      card.rotation = angle[-1 * (idx - 9)] * (Math.PI / 180);
      card
        .on("pointerover", () => {
          if (vm.step != 0) return;
          card.filters = this.cardOutline;
        })
        .on("pointerout", () => {
          card.filters = null;
        })
        .on("pointerdown", (e) => {
          if (vm.step != 0) return;
          vm.step = 1;
          this.deathCard = new PIXI.Sprite(
            this.loader.resources["card_death"].texture
          );
          this.deathCard.width = this.background.width * 0.188;
          this.deathCard.height = this.background.height * 0.475;
          // this.deathCard.pivot.set(this.deathCard.width/2,this.deathCard.height);
          this.deathCard.anchor.x = 0.5;
          this.deathCard.anchor.y = 0.6;
          let scaleTarget = this.deathCard.scale.x;
          this.deathCard.scale.x = 0;
          this.cardContainer.addChild(this.deathCard);
          card.zIndex = 999;
          let tl = gsap.timeline({ default: { ease: "power2.out" } });
          tl.to(vm.cards[idx], {
            duration: 1,
            pixi: { scaleX: 0, x: 0, y: -80, rotation: 0 },
          })
            .set(vm.deathCard, { pixi: { scaleX: 0, x: 0, y: -80 } })
            .to(
              vm.deathCard,
              {
                duration: 1,
                pixi: { scaleX: scaleTarget },
                onComplete: () => {
                  vm.popData.text =
                    "天啊...是死神牌！！你....需要我特製的「祝福糖漿」來替你消災解厄。";
                  vm.$emit("setTapHide");
                },
              },
              "showDeath"
            )
            .to(
              vm.cards[0],
              {
                duration: 0.5,
                pixi: { alpha: 0 },
                onComplete: () => {
                  vm.cards[0].destroy();
                },
              },
              "showDeath"
            )
            .to(
              vm.cards[1],
              {
                duration: 0.5,
                pixi: { alpha: 0 },
                onComplete: () => {
                  vm.cards[1].destroy();
                },
              },
              "showDeath"
            )
            .to(
              vm.cards[2],
              {
                duration: 0.5,
                pixi: { alpha: 0 },
                onComplete: () => {
                  vm.cards[2].destroy();
                },
              },
              "showDeath"
            )
            .to(
              vm.cards[3],
              {
                duration: 0.5,
                pixi: { alpha: 0 },
                onComplete: () => {
                  vm.cards[3].destroy();
                },
              },
              "showDeath"
            )
            .to(
              vm.cards[4],
              {
                duration: 0.5,
                pixi: { alpha: 0 },
                onComplete: () => {
                  vm.cards[4].destroy();
                },
              },
              "showDeath"
            )
            .to(
              vm.cards[5],
              {
                duration: 0.5,
                pixi: { alpha: 0 },
                onComplete: () => {
                  vm.cards[5].destroy();
                },
              },
              "showDeath"
            )
            .to(
              vm.cards[6],
              {
                duration: 0.5,
                pixi: { alpha: 0 },
                onComplete: () => {
                  vm.cards[6].destroy();
                },
              },
              "showDeath"
            )
            .to(
              vm.cards[7],
              {
                duration: 0.5,
                pixi: { alpha: 0 },
                onComplete: () => {
                  vm.cards[7].destroy();
                },
              },
              "showDeath"
            )
            .to(
              vm.cards[8],
              {
                duration: 0.5,
                pixi: { alpha: 0 },
                onComplete: () => {
                  vm.cards[8].destroy();
                },
              },
              "showDeath"
            )
            .to(
              vm.cards[9],
              {
                duration: 0.5,
                pixi: { alpha: 0 },
                onComplete: () => {
                  vm.cards[9].destroy();
                },
              },
              "showDeath"
            );
        });
      this.cards.push(card);
      this.cardContainer.addChild(card);
      this.cardContainer.x = this.app.screen.width / 2;
      this.cardContainer.y = this.app.screen.height / 2;
    },
    setMaterial() {
      let vm = this;
      let threeCard = ["stone", "drug", "feather"];
      this.cards = [];
      let scaleTarget;
      for (let index = 0; index < 3; index++) {
        let card = new PIXI.Sprite(
          this.loader.resources[threeCard[index]].texture
        );
        let cardGap = 0;
        card.width = this.background.width * 0.188;
        card.height = this.background.height * 0.475;
        cardGap = card.width / 10;
        scaleTarget = card.scale.x;
        card.x = (index - 1) * card.width + (index - 1) * cardGap;
        card.y = -80;
        card.anchor.x = 0.5;
        card.anchor.y = 0.6;
        card.interactive = true;
        card.scale.x = 0;
        card.active = false;
        card.buttonMode = true;
        card
          .on("pointerover", () => {
            //card.filters = this.cardOutline;
            vm.cards[index].filters = this.cardOutline;
          })
          .on("pointerout", () => {
            //card.filters = null;
            vm.cards[index].filters = null;
          })
          .on("pointerdown", () => {
            if (vm.cardsActive < 2 && card.active == false) {
              vm.cardsActive++;
              card.active = true;
            } else if (vm.cardsActive > 0 && card.active == true) {
              card.active = false;
              vm.cardsActive--;
            }
          });
        this.cards.push(card);
        this.cardContainer.addChild(card);
      }
      let tl = gsap.timeline({ default: { ease: "power2.out", duration: 3 } });
      tl.to(vm.deathCard, {
        pixi: { scaleX: 0 },
        onComplete: () => {
          vm.deathCard.destroy();
        },
      })
        .to(vm.cards[0], { pixi: { scaleX: scaleTarget } }, "opencard")
        .to(vm.cards[1], { pixi: { scaleX: scaleTarget } }, "opencard")
        .to(
          vm.cards[2],
          {
            pixi: { scaleX: scaleTarget },
            onComplete: () => {
              vm.app.ticker.add((delta) => vm.gameLoop(delta));
            },
          },
          "opencard"
        );
    },
    setResult() {
      let vm = this;
      let active = [];
      let result = "";
      this.cards.forEach((item) => {
        active.push(item.active);
      });
      if (active[0] && active[1]) {
        result = "blueResult";
        vm.popData.text =
          "祝福糖漿「寧靜」：水藍色的清澈液體，據傳能帶給人們由內而外的無比平靜。";
        vm.popData.result = "blue";
      } else if (active[2] && active[1]) {
        result = "greenResult";
        vm.popData.text =
          "祝福糖漿「覺察」：薄荷綠的液體中有小小結晶，能讓你放大感官，讓五官瞬間變得敏銳。";
        vm.popData.result = "green";
      } else if (active[2] && active[0]) {
        result = "yellowResult";
        vm.popData.text =
          "祝福糖漿「狂喜」：閃爍金黃色的濃稠液體，能讓你回想起此生最快樂的感受。";
        vm.popData.result = "yellow";
      }
      this.cardResult = new PIXI.Sprite(this.loader.resources[result].texture);
      this.cardResult.width = this.background.width * 0.188;
      this.cardResult.height = this.background.height * 0.475;
      let scaleTarget = this.cardResult.scale.x;
      this.cardResult.x = 0;
      this.cardResult.y = 0;
      this.cardResult.anchor.x = 0.5;
      this.cardResult.anchor.y = 0.6;
      this.cardResult.scale.x = 0;
      this.cardResult.y = -80;
      this.cardContainer.addChild(this.cardResult);
      let tl = gsap.timeline({ default: { ease: "power2.out", duration: 3 } });
      tl.to(vm.cards[0], { pixi: { scaleX: 0 } }, "closecard")
        .to(vm.cards[1], { pixi: { scaleX: 0 } }, "closecard")
        .to(vm.cards[2], { pixi: { scaleX: 0 } }, "closecard")
        .to(this.cardResult, {
          pixi: { scaleX: scaleTarget },
          onComplete: () => {
            vm.cards[0].destroy();
            vm.cards[1].destroy();
            vm.cards[2].destroy();
          },
        });
    },
    gameLoop(delta) {
      let vm = this;
      if (this.step == 2) {
        this.cards.forEach((item, index) => {
          if (item.active) {
            vm.cards[index].filters = this.cardOutline;
          } else {
            vm.cards[index].filters = null;
          }
        });
      }
    },
    onComplete() {
      this.pop = {
        PopStep: "gameEnd",
        gameType: "CardGame",
        status: true,
      };
      this.cardResult.destroy();
    },
    submit() {
      if (this.step == 2 && this.cardsActive == 2) {
        this.step = 3;
        this.setResult();
      }
    },
    setClose() {
      let vm = this;
      this.close = new PIXI.Sprite(this.loader.resources["close"].texture);
      this.close.x = 30 - this.background.width/2;
      this.close.y = 30 - this.background.height/2;
       this.close.height = this.close.width = this.background.width*0.03;
      this.close.interactive = true;
      this.close.on("pointerdown", function (e) {
        sound.stop("my-sound");
        vm.$emit("back", 6); // 6: Failed
      });
      this.centerContainer.addChild(this.close);
    },
    back() {
      sound.stop("my-sound");
      this.$emit("back", 2); //2: CardGame
    },
    again() {
      this.cards = [];
      this.cardsActive = this.step = 0;
      this.popData = {
        status: false,
        avatar: "cometlady",
        name: "彗星夫人",
        text: "",
        gameType: "cardgame",
      };
      this.closePop();
      for (let index = 0; index < 10; index++) {
        this.setCard(index, 10);
      }
    },
    tapping() {
      if (this.step == 1) {
        this.goStepTwo();
        this.$emit("setTapHide");
      }
    },
  },
  destroyed() {
    this.app.destroy();
  },
  // watch:{
  //     tapStatus(){
  //         if(this.tapStatus){
  //             if(this.step==1){
  //                 this.goStepTwo();
  //                 this.$emit('setTapHide');
  //             }
  //         }
  //     }
  // }
};
</script>
<style lang="scss" scoped>
.cardgame {
  width: 100%;
  height: 100%;
  display: block;
  // position: relative;
  canvas {
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
    // height:100%;
    // width: 100%;
    // @media screen and (min-width: 320px) and (max-width: 767px) and (orientation:portrait) {
    //     //transform: rotate(-90deg) translate(-50%, -50%);
    //     transform: rotate(-90deg);
    //     height: 100vh;
    //     width: 100vw;
    //     bottom: -100vh;
    //     transform-origin: 0px 0px;
    //     // bottom: 36.3%;
    //     // left: 60.8%;
    // }
  }
}
</style>