<template>
  <div class="horror">
    <canvas id="myCanvas" ref="myCanvas"></canvas>
    <PopUp
      v-if="pop.status"
      :PopStep="pop.PopStep"
      @closePop="closePop"
      :gameType="pop.gameType"
      @nextState="nextState"
      :gameState="pop.gameState"
      @back="back"
      @again="again"
    />
  </div>
</template>
<script>
import PopUp from "@/components/PopUp.vue";
import * as PIXI from "pixi.js";
import { gsap } from "gsap";
import { PixiPlugin } from "gsap/PixiPlugin";
import { sound } from "@pixi/sound";
export default {
  name: "horror",
  data: () => ({
    app: null,
    keys: {},
    deltaTime: null,
    loader: null,
    pop: {
      PopStep: "game",
      gameType: "Horror",
      status: true,
      gameState: 0,
    },
  }),
  mounted() {
    this.setMediaLoad();
  },
  components: { PopUp },
  methods: {
    init() {
      this.setCanvas();
      this.setKeyboard();
      this.setElement();
    },
    setCanvas() {
      let vm = this;
      let myCanvas = this.$refs.myCanvas;
      this.app = new PIXI.Application({
        width: 1920,
        height: 1080,
        antialias: true,
        transparent: false,
        resolution: 2,
        view: myCanvas,
      });
      this.app.renderer.view.style.position = "absolute";
      this.app.renderer.view.style.display = "block";
      this.app.renderer.autoResize = true;
      if (window.matchMedia("(orientation: portrait)").matches) {
        this.app.renderer.resize(window.innerHeight, window.innerWidth);
        this.windowWidth = window.innerHeight;
        this.windowHeight = window.innerWidth;
      } else {
        this.app.renderer.resize(window.innerWidth, window.innerHeight);
        this.windowWidth = window.innerWidth;
        this.windowHeight = window.innerHeight;
      }

      this.app.stage.interactive = true;
      this.app.ticker.add((delta) => vm.gameLoop(delta));

      gsap.registerPlugin(PixiPlugin);
      PixiPlugin.registerPIXI(PIXI);

      sound.add("my-sound", "/game/horror/music.mp3");
      sound.play("my-sound", { loop: true });
    },
    setKeyboard() {
      let vm = this;
      window.addEventListener("keydown", vm.keyDown);
      window.addEventListener("keyup", vm.keyUp);
    },
    keyDown(e) {
      this.keys[e.keyCode] = true;
    },
    keyUp(e) {
      this.keys[e.keyCode] = false;
    },
    setMediaLoad() {
      this.loader = new PIXI.Loader();
      this.loader.reset();
      this.loader
        .add([
          { name: "sheet", url: "/game/horror/1.json" },
          { name: "fSheet", url: "/game/horror/2.json" },
          { name: "close", url: "./game/close.png" },
        ])
        .load(this.init);
    },
    setElement() {
      const defaultIcon = "url('./game/horror/matches.png'),auto";
      console.log(defaultIcon);
      this.app.renderer.plugins.interaction.cursorStyles.default = defaultIcon;

      let sheet = this.loader.resources.sheet.spritesheet;
      let hSheet = this.loader.resources.fSheet.spritesheet;

      this.bg = new PIXI.Sprite(sheet.textures["bg.png"]);
      this.get = new PIXI.Sprite(sheet.textures["get.png"]);
      this.head = new PIXI.Sprite(sheet.textures["head.png"]);
      this.light_01 = new PIXI.Sprite(sheet.textures["light_0.png"]);
      this.light_02 = new PIXI.Sprite(sheet.textures["light_0.png"]);
      this.light_03 = new PIXI.Sprite(sheet.textures["light_0.png"]);
      this.light_04 = new PIXI.Sprite(sheet.textures["light_0.png"]);
      this.light_1 = new PIXI.Sprite(hSheet.textures["light_1.png"]);
      this.light_2 = new PIXI.Sprite(hSheet.textures["light_2.png"]);
      this.light_3 = new PIXI.Sprite(hSheet.textures["light_3.png"]);
      this.light_4 = new PIXI.Sprite(hSheet.textures["light_4.png"]);
      this.light_5 = new PIXI.Sprite(hSheet.textures["light_5.png"]);

      this.spider_1 = new PIXI.Sprite(sheet.textures["spider_1.png"]);
      this.spider_2 = new PIXI.Sprite(sheet.textures["spider_2.png"]);
      this.spider_3 = new PIXI.Sprite(hSheet.textures["spider_3.png"]);
      this.spider_4 = new PIXI.Sprite(sheet.textures["spider_4.png"]);
      this.unlight_01 = new PIXI.Sprite(hSheet.textures["unlight_0.png"]);
      this.unlight_02 = new PIXI.Sprite(hSheet.textures["unlight_0.png"]);
      this.unlight_03 = new PIXI.Sprite(hSheet.textures["unlight_0.png"]);
      this.unlight_04 = new PIXI.Sprite(hSheet.textures["unlight_0.png"]);
      this.unlight_05 = new PIXI.Sprite(hSheet.textures["unlight_0.png"]);
      this.unlight_1 = new PIXI.Sprite(hSheet.textures["unlight_1.png"]);
      this.unlight_2 = new PIXI.Sprite(hSheet.textures["unlight_2.png"]);
      this.unlight_3 = new PIXI.Sprite(hSheet.textures["unlight_3.png"]);
      this.unlight_4 = new PIXI.Sprite(hSheet.textures["unlight_4.png"]);

      this.lightArr = [
        { sprite: this.light_01, status: "head" },
        { sprite: this.light_02, status: "spider" },
        { sprite: this.light_03, status: "spider" },
        { sprite: this.light_04, status: "none" },
        { sprite: this.light_1, status: "get" },
        { sprite: this.light_2, status: "get" },
        { sprite: this.light_3, status: "none" },
        { sprite: this.light_4, status: "spider" },
        { sprite: this.light_5, status: "head" },
      ];
      this.unlightArr = [
        { sprite: this.unlight_01, hasTrigger: false },
        { sprite: this.unlight_02, hasTrigger: false },
        { sprite: this.unlight_03, hasTrigger: false },
        { sprite: this.unlight_04, hasTrigger: false },
        { sprite: this.unlight_05, hasTrigger: false },
        { sprite: this.unlight_1, hasTrigger: false },
        { sprite: this.unlight_2, hasTrigger: false },
        { sprite: this.unlight_3, hasTrigger: false },
        { sprite: this.unlight_4, hasTrigger: false },
      ];

      this.gameArr = [];

      this.positionArr = [
        [540, 85],
        [840, 73],
        [1140, 73],
        [540, 385],
        [840, 373],
        [1140, 373],
        [540, 685],
        [840, 673],
        [1140, 673],
      ];

      for (let i = 0; i < 9; i++) {
        let num1 = Math.floor(Math.random() * this.lightArr.length);
        let num2 = Math.floor(Math.random() * this.unlightArr.length);

        let light = this.lightArr[num1];
        this.lightArr.splice(num1, 1);
        let unlight = this.unlightArr[num2];
        this.unlightArr.splice(num2, 1);

        this.gameArr.push({
          unlight: unlight.sprite,
          light: light.sprite,
          status: light.status,
          hasTrigger: unlight.hasTrigger,
          position: this.positionArr[i],
        });
      }

      this.container = new PIXI.Container();
      this.container.addChild(this.bg);

      this.gameArr.forEach((element, index) => {
        element.unlight.interactive = true;
        element.unlight.x = element.position[0];
        element.unlight.y = element.position[1];
        element.light.x = element.position[0];
        element.light.y = element.position[1];
        element.unlight
          .on("pointerdown", function () {
            this.visible = false;
            element.hasTrigger = true;
          })
          .on("pointerdown", this.onTrigger);
        this.container.addChild(element.light);
        this.container.addChild(element.unlight);
      });

      this.overlay = new PIXI.Graphics();
      this.overlay.beginFill(0x000000);
      this.overlay.lineStyle(0, 0x000000);
      this.overlay.drawRect(0, 0, this.bg.width, this.bg.height);
      this.overlay.alpha = 0.5;
      this.overlay.interactive = true;
      this.overlay.visible = false;

      this.container.addChild(
        this.get,
        this.overlay,
        this.head,
        this.spider_1,
        this.spider_2,
        this.spider_3,
        this.spider_4
      );
      this.get.visible = false;
      this.head.visible = false;
      this.spider_1.visible = false;
      this.spider_2.visible = false;
      this.spider_3.visible = false;
      this.spider_4.visible = false;

      this.ratio = this.bg.height / this.bg.width;
      this.windowRatio = this.windowHeight / this.windowWidth;

      if (this.ratio < this.windowRatio) {
        this.container.width = this.windowWidth;
        this.container.height = this.container.width * this.ratio;
      } else {
        this.container.height = this.windowHeight;
        this.container.width = this.container.height / this.ratio;
      }

      this.getCount = 0;

      this.setClose();
      this.oncesuccess = false;

      this.app.stage.addChild(this.container);
      this.app.stage.pivot.set(
        -(this.windowWidth - this.container.width) / 2,
        0
      );
    },
    onTrigger() {
      let element = null;

      this.gameArr.forEach((e) => {
        if (e.hasTrigger) {
          element = e;
          e.hasTrigger = false;
        }
      });
      switch (element.status) {
        case "spider":
          this.onSpider();
          break;
        case "head":
          this.onHead();
          break;
        case "get":
          let x = element.light.x + element.light.width / 2;
          let y = element.light.y + element.light.height / 2;
          this.onGet(x, y);
          break;
        case "none":
          break;
        default:
          console.log("gameArr has something wrong");
      }
    },
    onSpider() {
      this.spider_1.x = (this.bg.width / 5) * 1;
      this.spider_2.x = (this.bg.width / 5) * 2;
      this.spider_3.x = (this.bg.width / 5) * 3;
      this.spider_4.x = (this.bg.width / 5) * 4;
      this.spider_1.y = 0;
      this.spider_2.y = 0;
      this.spider_3.y = 0;
      this.spider_4.y = 0;
      this.spider_1.visible = true;
      this.spider_2.visible = true;
      this.spider_3.visible = true;
      this.spider_4.visible = true;
      this.overlay.visible = true;

      gsap.to(this.spider_1, {
        pixi: {
          y: this.spider_1.height,
        },
        duration: 1.5,
        ease: "bounce.out",
      });
      gsap.to(this.spider_2, {
        pixi: {
          y: this.spider_2.height,
        },
        duration: 2,
        ease: "bounce.out",
      });
      gsap.to(this.spider_3, {
        pixi: {
          y: this.spider_3.height,
        },
        duration: 1.5,
        ease: "bounce.out",
      });
      gsap.to(this.spider_4, {
        pixi: {
          y: this.spider_4.height,
        },
        duration: 2,
        ease: "bounce.out",
      });
      gsap.to(this.spider_1, {
        pixi: {
          visible: false,
        },
        duration: 2.75,
      });
      gsap.to(this.spider_2, {
        pixi: {
          visible: false,
        },
        duration: 2.75,
      });
      gsap.to(this.spider_3, {
        pixi: {
          visible: false,
        },
        duration: 2.75,
      });
      gsap.to(this.spider_4, {
        pixi: {
          visible: false,
        },
        duration: 2.75,
      });
      gsap.to(this.overlay, {
        pixi: {
          visible: false,
        },
        duration: 2.75,
      });
    },
    onHead() {
      this.head.x = this.bg.width / 2 + this.head.width / 2;
      this.head.y = -this.head.height - 10;
      this.head.rotation = Math.PI;
      this.head.visible = true;
      this.overlay.visible = true;

      gsap.to(this.head, {
        pixi: {
          y: -10,
        },
        duration: 2,
        ease: "bounce.out",
      });
      gsap.to(this.head, {
        pixi: {
          visible: false,
        },
        duration: 2.75,
      });
      gsap.to(this.overlay, {
        pixi: {
          visible: false,
        },
        duration: 2.75,
      });
    },
    onGet(x, y) {
      this.getCount++;
      this.get.x = x;
      this.get.y = y;

      gsap.to(this.get, {
        pixi: {
          y: y - this.get.height,
          visible: true,
        },
        duration: 2,
        ease: "expo.out",
      });
      gsap.to(this.get, {
        pixi: {
          visible: false,
        },
        duration: 2.75,
      });

      if (this.getCount == 2) {
        setTimeout(this.onComplete, 1200);
      }
    },
    onComplete() {
      this.oncesuccess = true;
      this.pop = {
        PopStep: "gameEnd",
        gameType: "Horror",
        status: true,
      };
    },
    play(delta) {},
    gameLoop(delta) {
      this.deltaTime = delta;
      this.play(delta);
    },
    initGame() {
      this.getCount = 0;
      let tempArr = [];
      this.positionArr.forEach((e) => {
        tempArr.push(e);
      });

      for (let i = 0; i < 9; i++) {
        let num = Math.floor(Math.random() * tempArr.length);

        let position = tempArr[num];
        tempArr.splice(num, 1);

        this.gameArr[i].position = position;
      }

      this.gameArr.forEach((element) => {
        element.unlight.visible = true;
        element.unlight.x = element.position[0];
        element.unlight.y = element.position[1];
        element.light.x = element.position[0];
        element.light.y = element.position[1];
      });
    },
    nextState() {
      this.pop.gameState++;
    },
    closePop() {
      this.pop.status = false;
    },
    setClose() {
      let vm = this;

      this.close = new PIXI.Sprite(this.loader.resources["close"].texture);
      this.close.x = 50;
      this.close.y = 50;
      this.mask = new PIXI.Graphics();
      this.mask.beginFill(0x000000);
      this.mask.lineStyle(0, 0x000000);
      this.mask.alpha = 0;
      this.mask.drawRect(0, 0, 50 + this.close.width, 50 + this.close.height);
      this.mask.interactive = true;
      this.mask.on("pointerdown", this.back);
      this.container.addChild(this.close);
      this.container.addChild(this.mask);
    },
    back() {
      sound.stop("my-sound");
      if (this.oncesuccess) {
        this.$emit("back", 4); //4: Horror
      } else {
        this.$emit("back", 6); // 6: Failed
      }

      for (var i = this.app.stage.children.length - 1; i >= 0; i--) {
        this.app.stage.children[i].destroy({
          texture: true,
          baseTexture: true,
        });
      }
    },
    again() {
      this.initGame();
      this.pop = {
        PopStep: "game",
        gameType: "Horror",
        status: false,
      };
    },
  },
  destroyed() {},
};
</script>
<style lang="scss" scoped>
.horror {
  cursor: none;
  canvas {
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>