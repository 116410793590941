<template>
    <div class="help">
        <div class="container">
            <div class="column btn" :class="{zindex1:step==0}" @click="openPop('controlhelp')">
                <img src="@/assets/img/help/btn_help.svg" alt="" draggable="false">
                <img v-if="step==0&&firstTime" src="@/assets/img/help/img_help.svg" alt="" draggable="false">
            </div>
            <div class="column btn" :class="{zindex1:step==1}" @click="openPop('faq')">
                <img src="@/assets/img/help/btn_QA.svg" alt="" draggable="false">
                <img v-if="step==1&&firstTime" src="@/assets/img/help/img_QA.svg" alt="" draggable="false">
            </div>
            <div class="blackBlock" style="touch-action: none;" v-if="firstTime&&step!=null" @click="nextStep"></div>
        </div>
    </div>
</template>
<script>
export default {
    name:'Help',
    data:()=>({
        step:null
    }),
    props:{
        firstTime:{
            default:false
        },
        chrstatus:false
    },
    mounted(){
    },
    methods:{
        setTutorial(){
            console.log('setTutorial');
            let vm = this;
            vm.step = 0;
            // let timer = window.setInterval(()=>{
            //     vm.nextStep();
            //     if(vm.step>1){
            //         window.clearInterval(timer);
            //         vm.step = null;
            //         vm.$emit('startStory');
            //     }
            // },3000)
        },
        nextStep(){
            let vm = this;
            if(this.step!=null){
                this.step++;
                if(vm.step>1){
                    vm.step = null;
                    // vm.$emit('startStory');
                }
            }
        },
        openPop(type){
            if(this.step==null)
                this.$emit('openHelp',type)
        }
    },
    watch:{
        chrstatus(){
            if(this.chrstatus)this.setTutorial();
        }
    }
}
</script>
<style lang="scss">
@import "@/assets/scss/_media.scss";
.help{
    position:fixed;
    width:100%;
    .container{
        position:absolute;
        max-width:178px;
        width:10%;
        right: 28px;
        display: flex;
        top: 28px;
        justify-content: space-between;
        @include multiBreakpoint(tablet,tabletS){
            width:104px;
        }
        @include breakpoint(phone){
            width:84px;
        }
        .column{
            width:46%;
            position: relative;
            img{
                &:nth-of-type(2){
                    position: absolute;
                    width: 500%;
                    right: 261%;
                    transform: translateX(50%);
                    max-width: 439px;
                    bottom: -140%;
                }
            }
        }
        .zindex1{
            z-index: 1;
        }
    }
    .blackBlock{
        position:fixed;
        width:100%;
        height:100vh;
        background-color:rgba($color: #000000, $alpha: .5);
        top: 0;
        left: 0;
    }
}
</style>