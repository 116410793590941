<template>
    <div class="clock">
        <countdown :time="time" :interval="100" @progress="handleCountdownProgress">
        </countdown>
        <div class="countTex">
            <p>大事件倒數</p>
            <p>{{daysTens}}{{daysUnit}}日{{hoursTens}}{{hoursUnit}}時{{minutesTens}}{{minutesUnit}}分</p>
        </div>
        <!-- <div class="btnWrap" v-if="SHOWEND" @click="goUrl">
            <img src="@/assets/img/cover/btn_watch.png" alt="" draggable="false">
            <img src="@/assets/img/cover/btn_watch_active.png" alt="" draggable="false">
        </div> -->
    </div>
</template>
<script>
let now = new Date();
let countDownEnd = new Date('2021/10/30 12:00:00');
let _time = Math.abs(countDownEnd - now);
if(countDownEnd - now <= 0 ){
    _time = new Date('2021/10/30 12:00:00') - new Date('2021/10/25 12:00:00');
}
export default {
    name:'clock',
    data:()=>({
        time: _time,
        daysHuns: 0,
        daysTens: 0,
        daysUnit: 0,
        hoursTens: 0,
        hoursUnit: 0,
        minutesTens: 0,
        minutesUnit: 0,
        data: 0,
    }),
    props:{
        GAMEEND:{
            default:false
        },
        LOTTERYEND:{
            default:false
        },
        SHOWEND:{
            default:false
        }
    },
    mounted() {
        // let now = new Date();
        // let countDownEnd = new Date('2021/10/30 12:00:00');
        // let showEnd = new Date('2021/10/30 22:00:00');
        // if(countDownEnd - now <= 0 && !this.SHOWEND){
        //     this.$emit('setAlert');
        // }
        // if(showEnd - now <=0 && !this.SHOWEND){
        //     this.$emit('setShowEnd');
        // }
    },
    methods:{
        handleCountdownProgress(data) {
            let now = new Date();
            let gameEndTime = new Date('2021/10/25 00:00:00');
            let showEnd = new Date('2021/10/30 22:00:00');
            if(countDownEnd - now > 0){
                this.daysHuns = Math.floor(data.days%1000 / 100)
                this.daysTens = Math.floor(data.days%100 / 10)
                this.daysUnit = data.days % 10
                this.hoursTens = Math.floor(data.hours / 10)
                this.hoursUnit = data.hours % 10
                this.minutesTens = Math.floor(data.minutes / 10)
                this.minutesUnit = data.minutes % 10
                if(gameEndTime - now <=0 && !this.LOTTERYEND){
                    this.$emit('setLotteryEnd');
                }
            }else{
                this.daysHuns = 0
                this.daysTens = 0
                this.daysUnit = 0
                this.hoursTens = 0
                this.hoursUnit = 0
                this.minutesTens = 0
                this.minutesUnit = 0
                // if(!this.GAMEEND&&!this.SHOWEND){
                //     this.$emit('setGameEnd');
                //     this.$emit('setAlert');
                // }
            }
            if(showEnd - now <=0 && !this.SHOWEND){
                this.$emit('setShowEnd');
            }
        },
        goUrl(){
            window.open('https://ntch-lunatictown.com/programs', '_blank');
        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_media.scss";
.clock{
    background-image:url('~@/assets/img/clock/countdown.svg');
    //width: 11.4%;
    width:163px;
    // padding-bottom: 5%;
    padding-bottom:72px;
    background-repeat: no-repeat;
    background-size: 100%;
    @include multiBreakpoint(tablet,tabletS){
        width: 140px;
        padding-bottom: 63px;
    }
    @include breakpoint(phone){
        width: 108px;
        padding-bottom: 48px;
    }
    .countTex{
        position: absolute;
        left: 24%;
        top: 24%;
        font-size: 25px;
        letter-spacing: 1px;
        @include multiBreakpoint(tablet,tabletS){
            font-size: 20px;
        }
        @include breakpoint(phone){
            font-size: 16px;
        }
        p{
            &:nth-of-type(1){
                color:#2458BB;
            }
        }
    }
    .btnWrap{
        position:absolute;
        right: -249px;
        width: 200px;
        @include multiBreakpoint(tablet,tabletS){
            right: -176px;
            width: 156px;
        }
        @include breakpoint(phone){
            right: -104px;
            width: 100px;
        }
        img{
            &:nth-of-type(2){
                display: none;
            }
        }
        &:hover,&:focus{
            img{
                &:nth-of-type(1){
                    display: none;
                }
                &:nth-of-type(2){
                    display: block;
                }
            }
        }
    }
}
</style>