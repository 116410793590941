<template>
  <div class="donategame">
    <canvas id="myCanvas" ref="myCanvas"></canvas>
    <PopUp
      v-if="pop.status"
      :PopStep="pop.PopStep"
      @closePop="closePop"
      :gameType="pop.gameType"
      @nextState="nextState"
      :gameState="pop.gameState"
      @back="back"
      @again="again"
      @tapping="tapping"
    />
  </div>
</template>
<script>
import * as PIXI from "pixi.js";
import * as particles from "pixi-particles";
import { gsap } from "gsap";
import PixiPlugin from "gsap/PixiPlugin";
import PopUp from "@/components/PopUp.vue";
import { sound } from "@pixi/sound";
export default {
  name: "DonateGame",
  data: () => ({
    app: null,
    loader: null,
    centerContainer: null,
    cardContainer: null,
    scoreEnergy: null,
    background: null,
    scoreEnergy: null,
    scoreBack: null,
    scorebar: null,
    score: { num: 0 },
    heartBtn: null,
    emitterContainer: null,
    elapsed: null,
    emitter: null,
    clicked: false,
    timer: null,
    releaseOffset: 0,
    pop: {
      PopStep: "game",
      gameType: "DonateGame",
      status: true,
      gameState: 0,
    },
    keys: {},
    step: 0,
    ratio: null,
    windowRatio: null,
    initRotation: null,
    close:null
  }),
  mounted() {
    this.setMediaLoad();
  },
  computed: {
    detectMob() {
      const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i,
      ];
      return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
      });
    },
  },
  props: {
    tapStatus: {
      default: false,
    },
  },
  components: { PopUp },
  methods: {
    setMediaLoad() {
      this.loader = new PIXI.Loader();
      this.loader.reset();
      this.loader
        .add([
          { name: "bg", url: "./game/donategame/bg.png" },
          { name: "scorebar", url: "./game/donategame/scorebar.png" },
          { name: "heartBtn", url: "./game/donategame/btn_press.png" },
          { name: "dance", url: "./game/donategame/dance.json" },
          // { name: "chat1", url: "./game/donategame/chat.png" },
          // { name: "chat2", url: "./game/donategame/chat1.png" },
          // { name: "chat3", url: "./game/donategame/chat2.png" },
          { name: "messageboard", url: "./game/donategame/messageboard.png" },
          { name: "chat", url: "./game/donategame/chat.json" },
          { name: "messageAni", url: "./game/donategame/messageAni.json" },
          { name: "close", url: "./game/close.png" },
        ])
        .load(this.init);
    },
    init() {
      this.setCanvas();
      this.setup();
      this.setModel();
      this.setHeartBtn();
      this.setClose();
      this.setScoreBar();
      this.setParticle();
      this.setKeyboard();
    },
    setKeyboard() {
      let vm = this;
      window.addEventListener("keydown", vm.keyDown);
      window.addEventListener("keyup", vm.keyUp);
    },
    keyDown(e) {
      this.keys[e.keyCode] = true;
      if (this.pop.gameState == 0 && this.keys["32"]) {
        this.nextState();
      }
    },
    keyUp(e) {
      this.keys[e.keyCode] = false;
    },
    setCanvas() {
      let vm = this;
      let myCanvas = this.$refs.myCanvas;
      this.app = new PIXI.Application({
        width: 1920,
        height: 1080,
        antialias: true,
        transparent: false,
        resolution: 2,
        view: myCanvas,
      });
      this.app.renderer.view.style.position = "absolute";
      this.app.renderer.view.style.display = "block";
      this.app.renderer.autoResize = true;
      let screen = this.app.renderer.screen;
      this.app.stage.interactive = true;
      if (window.matchMedia("(orientation: portrait)").matches) {
        this.app.renderer.resize(window.innerHeight, window.innerWidth);
        this.windowWidth = window.innerHeight;
        this.windowHeight = window.innerWidth;
      } else {
        this.app.renderer.resize(window.innerWidth, window.innerHeight);
        this.windowWidth = window.innerWidth;
        this.windowHeight = window.innerHeight;
      }
      sound.add("my-sound", "/game/donategame/music.mp3");
      sound.play("my-sound", { loop: true });
      // window.onresize = function (event){
      //     if (window.matchMedia("(orientation: portrait)").matches) {
      //         vm.app.renderer.resize(window.innerHeight, window.innerWidth);
      //         vm.windowWidth = window.innerHeight;
      //         vm.windowHeight = window.innerWidth;
      //     } else {
      //         vm.app.renderer.resize(window.innerWidth, window.innerHeight);
      //         vm.windowWidth = window.innerWidth;
      //         vm.windowHeight = window.innerHeight;
      //     }
      // }
      // this.initRotation = 'L';
      // if(window.innerWidth<window.innerHeight){
      //     this.app.stage.position.set(screen.width/2, screen.height/2); // center of the screen
      //     this.app.stage.rotation = Math.PI/2;
      //     this.app.stage.pivot.set(screen.width/2, screen.height/2);
      //     this.initRotation = 'O';
      // }
      // window.onresize = function (event){
      //     vm.app.renderer.resize(window.innerWidth, window.innerHeight);
      //     screen = vm.app.renderer.screen;
      //     if(vm.initRotation == 'O'){
      //         if(window.innerWidth>window.innerHeight){
      //             vm.app.stage.pivot.set(screen.height/2,screen.width/2);
      //             vm.app.stage.position.set(screen.width/2, screen.height/2);
      //             vm.app.stage.rotation = 0;
      //         }else{
      //             vm.app.stage.position.set(screen.width/2, screen.height/2);
      //             vm.app.stage.pivot.set(screen.width/2, screen.height/2);
      //             vm.app.stage.rotation = Math.PI/2;
      //         }
      //     }else{
      //         if(window.innerWidth>window.innerHeight){
      //             vm.app.stage.position.set(0,0);
      //             vm.app.stage.pivot.set(0, 0);
      //             vm.app.stage.rotation = 0;
      //         }else{
      //             vm.app.stage.position.set(screen.width/2, screen.height/2);
      //             vm.app.stage.pivot.set(screen.height/2,screen.width/2);
      //             vm.app.stage.rotation = Math.PI/2;
      //         }
      //     }
      //     // 將畫面的正中間放在 app.renderer 一半寬高的位置
      //     // this.app.stage.x = app.renderer.width * 0.5;
      //     // this.app.stage.y = app.renderer.height * 0.5;
      // };
    },
    setup() {
      let vm = this;
      const defaultIcon = "default,auto";
      this.app.renderer.plugins.interaction.cursorStyles.default = defaultIcon;
      let sheet = this.loader.resources.messageAni.spritesheet;
      let messageboard = new PIXI.AnimatedSprite(
        sheet.animations[`messageboard`]
      );
      this.background = new PIXI.Sprite(this.loader.resources["bg"].texture);
      // this.background.width = this.app.screen.height/9*16;
      // this.background.height = this.app.screen.height;
      this.background.anchor.x = 0.5;
      this.background.anchor.y = 0.5;
      messageboard.width = this.background.width * 0.28;
      messageboard.height = this.background.height * 0.73;
      messageboard.anchor.x = 1;
      messageboard.anchor.y = 0.45;
      messageboard.x = this.background.width / 2 - 20;
      messageboard.play();
      messageboard.animationSpeed = 0.02;
      this.centerContainer = new PIXI.Container();
      this.centerContainer.position.x = this.app.screen.width / 2;
      //this.centerContainer.position.x = -(this.windowWidth-this.centerContainer.width)/2;
      this.centerContainer.position.y = this.app.screen.height / 2;
      this.centerContainer.addChild(this.background, messageboard);
      this.setResize();
      this.app.stage.addChild(this.centerContainer);
      //this.app.stage.pivot.set(-(this.windowWidth-this.container.width)/2, 0)
    },
    setResize() {
      this.ratio = this.background.height / this.background.width;
      this.windowRatio = this.windowHeight / this.windowWidth;
      if (this.ratio < this.windowRatio) {
        this.centerContainer.width = this.windowWidth;
        this.centerContainer.height = this.centerContainer.width * this.ratio;
      } else {
        this.centerContainer.height = this.windowHeight;
        this.centerContainer.width = this.centerContainer.height / this.ratio;
      }
    },
    setModel() {
      let sheet = this.loader.resources.dance.spritesheet;
      let dance = new PIXI.AnimatedSprite(sheet.animations[`dance`]);
      dance.height = this.background.height * 0.6;
      dance.width = this.background.width * 0.31;
      dance.play();
      dance.animationSpeed = 0.02;
      dance.anchor.x = 0.5;
      dance.anchor.y = 1;
      dance.y = this.background.height * 0.29;
      this.centerContainer.addChild(dance);
      this.setChat(dance);
    },
    setChat(dance) {
      let sheet = this.loader.resources.chat.spritesheet;
      let chat = new PIXI.AnimatedSprite(sheet.animations[`chat`]);
      chat.height = this.background.height * 0.17;
      chat.width = this.background.width * 0.13;
      chat.play();
      chat.animationSpeed = 0.01;
      chat.anchor.x = 1;
      chat.anchor.y = 1;
      chat.x = dance.x - dance.width / 2 + 100;
      chat.y = dance.y - dance.height + chat.height / 2;
      this.centerContainer.addChild(chat);
    },
    setScoreBar() {
      let vm = this;
      let scoreBarContainer = new PIXI.Container();
      this.scorebar = new PIXI.Sprite(
        this.loader.resources["scorebar"].texture
      );
      this.scorebar.width = this.background.width * 0.07;
      this.scorebar.height = this.background.height * 0.91;
      scoreBarContainer.x = this.close.x+this.close.width + 50/(-1 * this.background.width) / 2;
      //scoreBarContainer.y = this.background.height*0.045 - this.background.getGlobalPosition().y;
      scoreBarContainer.y =
        (-1 * this.background.height) / 2 +
        (this.background.height - this.scorebar.height) / 2;
      this.scoreEnergy = new PIXI.Graphics();
      this.scoreBack = new PIXI.Graphics();
      this.scoreBack.beginFill(0xe2e2e2);
      this.scoreBack.drawRect(
        0,
        0,
        this.scorebar.width * 0.4,
        this.scorebar.height * 0.84
      );
      this.scoreBack.endFill();
      this.scoreBack.x = (this.scorebar.width - this.scoreBack.width) / 2;
      this.scoreBack.y =
        this.scorebar.height -
        this.scorebar.height * 0.84 -
        this.background.height * 0.013;
      this.scoreEnergy.beginFill(0xffe86d);
      this.scoreEnergy.drawRect(0, 0, this.scorebar.width * 0.4, 0);
      this.scoreEnergy.endFill();
      this.scoreEnergy.x = this.scoreBack.x;
      this.scoreEnergy.y =
      this.scorebar.height - this.background.height * 0.013;
      scoreBarContainer.addChild(
        this.scoreBack,
        this.scoreEnergy,
        this.scorebar
      );
      this.centerContainer.addChild(scoreBarContainer);
    },
    setHeartBtn() {
      this.heartBtn = new PIXI.Sprite(
        this.loader.resources["heartBtn"].texture
      );
      this.heartBtn.cursor = "pointer,auto";
      this.heartBtn.width = this.heartBtn.height = this.background.width * 0.1;
      this.heartBtn.x = 0;
      this.heartBtn.y =
        this.background.height / 2 - this.heartBtn.height / 2 - 20;
      this.heartBtn.anchor.y = 0.5;
      this.heartBtn.anchor.x = 0.5;
      this.heartBtn.interactive = true;
      // if(this.detectMob)
      //     this.heartBtn.on('touchstart', this.heartPress);
      // else
      this.heartBtn.on("pointerdown", this.heartPress);
      this.heartBtn.on("pointerup", this.heartRelease);
      this.heartBtn.buttonMode = true;
      this.centerContainer.addChild(this.heartBtn);
    },
    setParticle() {
      let vm = this;
      this.emitterContainer = new PIXI.Container();
      this.centerContainer.addChild(this.emitterContainer);
      this.emitter = new particles.Emitter(
        // The PIXI.Container to put the emitter in
        // if using blend modes, it's important to put this
        // on top of a bitmap, and not use the root stage Container
        this.emitterContainer,
        // // The collection of particle images to use
        [
          PIXI.Texture.from("/game/donategame/heart1.png"),
          PIXI.Texture.from("/game/donategame/heart2.png"),
          PIXI.Texture.from("/game/donategame/heart3.png"),
          PIXI.Texture.from("/game/donategame/heart4.png"),
          PIXI.Texture.from("/game/donategame/heart5.png"),
        ],
        // Emitter configuration, edit this to change the look
        // of the emitter
        {
          alpha: {
            start: 1,
            end: 0,
          },
          scale: {
            start: 0.16,
            end: 1,
            minimumScaleMultiplier: 1,
          },
          color: {
            start: "#ffffff",
            end: "#ffffff",
          },
          speed: {
            start: 200,
            end: 50,
            minimumSpeedMultiplier: 3,
          },
          acceleration: {
            x: 0,
            y: 0,
          },
          maxSpeed: 0,
          startRotation: {
            min: -30,
            max: -150,
          },
          noRotation: true,
          rotationSpeed: {
            min: 0,
            max: 0,
          },
          lifetime: {
            min: 0.2,
            max: 0.8,
          },
          blendMode: "normal",
          frequency: 0.001,
          emitterLifetime: -1,
          maxParticles: 10,
          pos: {
            x: 0,
            y: 0,
          },
          addAtBack: false,
          spawnType: "point",
        }
      );
      this.emitter.updateOwnerPos(
        this.heartBtn.x + this.heartBtn.width / 2,
        this.heartBtn.y - this.heartBtn.height
      );
      this.elapsed = Date.now();
      this.emitterContainer.x = this.emitterContainer.x - 70;
      this.emitter.emit = false;
      this.emitter.emitterLifetime = 5;
      this.app.ticker.add((delta) => vm.gameLoop(delta));
    },
    heartRelease() {
      // let vm = this;
      // if(this.clicked){
      //     vm.timer = window.setTimeout(()=>{
      //         vm.clicked = false;
      //         vm.subtractEnergy();
      //     },3000);
      // }
      this.heartBtn.scale.x = this.heartBtn.scale.y =
        this.heartBtn.scale.x + 0.1;
    },
    heartPress() {
      let vm = this;
      this.emitter.emit = true;
      //this.emitter.playOnce();
      //console.log(this.emitter);
      let timer = window.setTimeout(() => {
        vm.emitter.emit = false;
        window.clearTimeout(timer);
      }, 500);
      this.clicked = true;
      this.debounce(this.addEnergy());
      this.heartBtn.scale.x = this.heartBtn.scale.y =
        this.heartBtn.scale.x - 0.1;
    },
    addEnergy() {
      let tl = gsap.timeline({
        default: { ease: "power2.out", duration: 0.2 },
      });
      let newScore = this.score.num + this.scoreBack.height / 50;
      if (newScore > this.scoreBack.height) {
        newScore = this.scoreBack.height;
        this.pop = {
          PopStep: "gameEnd",
          gameType: "DonateGame",
          status: true,
        };
      }
      tl.to(this.score, { num: newScore });
    },
    subtractEnergy() {
      let vm = this;
      if (!this.clicked) {
        vm.timer = window.setTimeout(
          () => {
            let tl = gsap.timeline({
              default: { ease: "power2.out", duration: 0.2 },
            });
            let newScore = vm.score.num - vm.scoreBack.height / 90;
            if (newScore < 0) newScore = 0;
            tl.to(vm.score, { num: newScore });
            vm.subtractEnergy();
          },
          3000 - vm.releaseOffset * 500 < 300
            ? 300
            : 3000 - vm.releaseOffset * 500
        );
      } else {
        vm.releaseOffset = 0;
        if (vm.timer) {
          window.clearTimeout(vm.timer);
        }
      }
    },
    gameLoop(delta) {
      let now = Date.now();
      // The emitter requires the elapsed
      // number of seconds since the last update
      this.emitter.update((now - this.elapsed) * 0.001);
      this.elapsed = now;
      //draw energy
      this.scoreEnergy.beginFill(0xffe86d);
      this.scoreEnergy.drawRect(
        0,
        0,
        this.scorebar.width * 0.4,
        this.score.num
      );
      this.scoreEnergy.endFill();
      this.scoreEnergy.height = this.score.num;
      this.scoreEnergy.x = this.scoreBack.x;
      this.scoreEnergy.y =
        this.scorebar.height - this.background.height * 0.013 - this.score.num;
    },
    debounce(func, delay = 200) {
      let timer = null;
      return () => {
        let context = this;
        let args = arguments;
        clearTimeout(timer);
        timer = setTimeout(() => {
          func.apply(context, args);
        }, delay);
      };
    },
    nextState() {
      this.pop.gameState++;
    },
    closePop() {
      this.pop.status = false;
    },
    setClose() {
      let vm = this;
      this.close = new PIXI.Sprite(this.loader.resources["close"].texture);
      this.close.x = 30 - this.background.width/2 ;
      this.close.y = 30 - this.background.height/2;
      this.close.height = this.close.width = this.background.width*0.03;
      this.close.interactive = true;
      this.close.on("pointerdown", function (e) {
        sound.stop("my-sound");
        vm.$emit("back", 6); // 6: Failed
      });
      this.centerContainer.addChild(this.close);
    },
    back() {
      sound.stop("my-sound");
      this.$emit("back", 1); //1: DonateGame
    },
    again() {
      this.score.num = 0;
      this.pop = {
        status: false,
      };
    },
    tapping() {
      if (this.pop.gameState == 0) {
        this.nextState();
        this.$emit("setTapHide");
      }
    },
    // resize() {
    //     if (window.matchMedia("(orientation: portrait)").matches) {
    //         this.app.renderer.resize(window.innerHeight, window.innerHeight);
    //         this.windowWidth = window.innerHeight;
    //         this.windowHeight = window.innerHeight;
    //     } else {
    //         this.app.renderer.resize(window.innerWidth, window.innerHeight);
    //         this.windowWidth = window.innerWidth;
    //         this.windowHeight = window.innerHeight;
    //     }
    // }
  },
  // watch:{
  //     tapStatus(){
  //         if(this.tapStatus){
  //             if(this.pop.gameState==0){
  //                 this.nextState();
  //                 this.$emit('setTapHide');
  //             }
  //         }
  //     }
  // },
  destroyed() {
    this.app.destroy();
  },
};
</script>
<style lang="scss" scoped>
.donategame {
  //cursor: none;
  canvas {
    // height:100%;
    // width: 100%;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
    // @media screen and (min-width: 320px) and (max-width: 767px) and (orientation:portrait) {
    //     //transform: rotate(-90deg) translate(-50%, -50%);
    //     transform: rotate(-90deg);
    //     height: 100vh;
    //     width: 100vw;
    //     bottom: -100vh;
    //     transform-origin: 0px 0px;
    // }
  }
}
</style>
© 2021 GitHub, Inc.
Terms
