<template>
  <div class="pushBottle">
    <canvas id="myCanvas" ref="myCanvas"></canvas>
    <PopUp
      v-if="pop.status"
      :PopStep="pop.PopStep"
      @closePop="closePop"
      :gameType="pop.gameType"
      @nextState="nextState"
      :gameState="pop.gameState"
      @back="back"
      @again="again"
    />
  </div>
</template>
<script>
import PopUp from "@/components/PopUp.vue";
import * as PIXI from "pixi.js";
import { gsap } from "gsap";
import { PixiPlugin } from "gsap/PixiPlugin";
import { sound } from "@pixi/sound";
export default {
  name: "pushBottle",
  data: () => ({
    app: null,
    keys: {},
    deltaTime: null,
    loader: null,
    pop: {
      PopStep: "game",
      gameType: "PushBottle",
      status: true,
      gameState: 0,
    },
  }),
  mounted() {
    this.setMediaLoad();
  },
  components: { PopUp },
  methods: {
    init() {
      this.setCanvas();
      this.setKeyboard();
      this.setElement();
    },
    setCanvas() {
      let vm = this;
      let myCanvas = this.$refs.myCanvas;
      this.app = new PIXI.Application({
        width: 1920,
        height: 1080,
        antialias: true,
        transparent: false,
        resolution: 2,
        view: myCanvas,
      });
      this.app.renderer.view.style.position = "absolute";
      this.app.renderer.view.style.display = "block";
      this.app.renderer.autoResize = true;
      if (window.matchMedia("(orientation: portrait)").matches) {
        this.app.renderer.resize(window.innerHeight, window.innerWidth);
        this.windowWidth = window.innerHeight;
        this.windowHeight = window.innerWidth;
      } else {
        this.app.renderer.resize(window.innerWidth, window.innerHeight);
        this.windowWidth = window.innerWidth;
        this.windowHeight = window.innerHeight;
      }
      this.app.stage.interactive = true;
      this.app.ticker.add((delta) => vm.gameLoop(delta));

      gsap.registerPlugin(PixiPlugin);
      PixiPlugin.registerPIXI(PIXI);

      sound.add("my-sound", "/game/pushBottle/music.mp3");
      sound.play("my-sound", { loop: true });
    },
    setKeyboard() {
      let vm = this;
      window.addEventListener("keydown", vm.keyDown);
      window.addEventListener("keyup", vm.keyUp);
    },
    keyDown(e) {
      this.keys[e.keyCode] = true;
    },
    keyUp(e) {
      this.keys[e.keyCode] = false;
    },
    setMediaLoad() {
      this.loader = new PIXI.Loader();
      this.loader.reset();
      this.loader
        .add([
          { name: "sheet", url: "/game/pushBottle/1.json" },
          { name: "close", url: "./game/close.png" },
        ])
        .load(this.init);
    },
    setElement() {
      let sheet = this.loader.resources.sheet.spritesheet;

      this.arrow = new PIXI.Sprite(sheet.textures["arrow.png"]);
      this.beer = new PIXI.Sprite(sheet.textures["beer.png"]);
      this.beer_remain_3 = new PIXI.Sprite(sheet.textures["beer_count.png"]);
      this.beer_empty_3 = new PIXI.Sprite(sheet.textures["beer_empty.png"]);
      this.beer_remain_2 = new PIXI.Sprite(sheet.textures["beer_count.png"]);
      this.beer_empty_2 = new PIXI.Sprite(sheet.textures["beer_empty.png"]);
      this.beer_remain_1 = new PIXI.Sprite(sheet.textures["beer_count.png"]);
      this.beer_empty_1 = new PIXI.Sprite(sheet.textures["beer_empty.png"]);
      this.bg = new PIXI.Sprite(sheet.textures["bg.png"]);
      this.btn_press = new PIXI.Sprite(sheet.textures["btn_press.png"]);
      this.power = new PIXI.Sprite(sheet.textures["power.png"]);
      this.power_bar = new PIXI.Sprite(sheet.textures["power_bar.png"]);
      this.score_bar = new PIXI.Sprite(sheet.textures["score_bar.png"]);
      this.add20 = new PIXI.Sprite(sheet.textures["add20.png"]);
      this.add50 = new PIXI.Sprite(sheet.textures["add50.png"]);
      this.add100 = new PIXI.Sprite(sheet.textures["add100.png"]);
      this.sunny = new PIXI.Sprite(sheet.textures["sunny.png"]);

      this.container = new PIXI.Container();

      this.container.addChild(
        this.bg,
        this.beer_empty_1,
        this.beer_empty_2,
        this.beer_empty_3,
        this.beer_remain_1,
        this.beer_remain_2,
        this.beer_remain_3,
        this.score_bar,
        this.arrow,
        this.add20,
        this.add50,
        this.add100,
        this.power,
        this.power_bar,
        this.beer,
        this.sunny,
        this.btn_press
      );

      this.beer_empty_1.x = 164;
      this.beer_empty_1.y = 60;
      this.beer_empty_2.x = 246;
      this.beer_empty_2.y = 60;
      this.beer_empty_3.x = 328;
      this.beer_empty_3.y = 60;
      this.beer_remain_1.x = 160;
      this.beer_remain_1.y = 40;
      this.beer_remain_2.x = 242;
      this.beer_remain_2.y = 40;
      this.beer_remain_3.x = 324;
      this.beer_remain_3.y = 40;
      this.score_bar.x = 1200;
      this.score_bar.y = 60;
      this.sunny.x = 1236;
      this.sunny.y = 386;
      this.arrow.x = 1190;
      this.arrow.y = 100;
      this.power.x = 59;
      this.power.y = 337;
      this.powerWidth = this.power.width;
      this.power_bar.x = 47;
      this.power_bar.y = 330;
      this.beer.x = 165;
      this.beer.y = 415;
      this.btn_press.x = 70;
      this.btn_press.y = 820;

      this.mask = new PIXI.Graphics();
      this.mask.beginFill(0x000000);
      this.mask.lineStyle(0, 0x000000);
      this.mask.drawRect(0, this.bg.height, this.bg.width, 50);
      this.container.addChild(this.mask);

      this.btn_press.interactive = true;
      this.btn_press.buttonMode = true;

      this.btn_press
        .on("pointerdown", this.onTapStart)
        .on("pointerup", this.onTapEnd)
        .on("pointerupoutside", this.onTapEnd);

      this.tapping = false;
      this.score = 0;
      this.powerCount = 0;
      this.playCount = 3;
      this.beerDistArr = [550, 1050, 1550, 1750];
      this.scoreArr = [20, 50, 100, 0];

      this.add20.x = this.beerDistArr[0] - 15;
      this.add20.y = 350;
      this.add20.visible = false;
      this.add50.x = this.beerDistArr[1] - 15;
      this.add50.y = 350;
      this.add50.visible = false;
      this.add100.x = this.beerDistArr[2] - 15;
      this.add100.y = 350;
      this.add100.visible = false;

      this.ratio = this.bg.height / this.bg.width;
      this.windowRatio = this.windowHeight / this.windowWidth;

      if (this.ratio < this.windowRatio) {
        this.container.width = this.windowWidth;
        this.container.height = this.container.width * this.ratio;
      } else {
        this.container.height = this.windowHeight;
        this.container.width = this.container.height / this.ratio;
      }

      this.setClose();
      this.app.stage.addChild(this.container);
      this.app.stage.pivot.set(
        -(this.windowWidth - this.container.width) / 2,
        -11
      );
    },
    onTapStart() {
      this.tapping = true;
      this.btn_press.alpha = 0.5;
    },
    onTapEnd() {
      this.tapping = false;
      this.btn_press.alpha = 1;
      this.value = parseInt(this.powerCount / 10);
      this.moveBeer();
    },
    play(delta) {},
    gameLoop(delta) {
      this.deltaTime = delta;
      this.play(delta);
      if (this.tapping) {
        this.powerCount += 1;
        this.powerCount %= 40;
        this.power.width = this.powerCount * (this.powerWidth / 40);
      }
    },
    moveBeer() {
      this.btn_press.interactive = false;
      this.btn_press.buttonMode = false;
      this.btn_press.alpha = 0.5;

      if (this.value == 3) {
        gsap.to(this.beer, {
          pixi: { x: this.beerDistArr[this.value] },
          duration: 0.7,
          onComplete: this.fallingdown,
        });
      } else {
        gsap.to(this.beer, {
          pixi: { x: this.beerDistArr[this.value] },
          duration: 1,
          onComplete: this.slidingstop,
        });
      }
    },

    slidingstop() {
      let add = null;

      if (this.value == 0) {
        add = this.add20;
      } else if (this.value == 1) {
        add = this.add50;
      } else if (this.value == 2) {
        add = this.add100;
      } else {
        add = null;
      }

      if (add != null) {
        add.visible = true;
        gsap.to(add, {
          pixi: { y: 280 },
          duration: 0.6,
        });
        gsap.to(add, {
          pixi: { visible: false },
          duration: 1,
          onComplete: this.addScore,
        });
        add.y = 350;
      } else {
        this.addScore();
      }
      
    },

    fallingdown() {
      gsap.to(this.beer, {
        pixi: {
          x: this.beerDistArr[3] + 50,
          y: this.bg.height,
          rotation: "140_cw",
        },
        duration: 0.7,
        onComplete: this.slidingstop,
      });
    },

    minusPlayCount() {
      this.playCount -= 1;
      switch (this.playCount) {
        case 2:
          this.beer_remain_3.visible = false;
          break;
        case 1:
          this.beer_remain_2.visible = false;
          break;
        case 0:
          this.beer_remain_1.visible = false;
          break;
        default:
          console.log("playcount has something wrong");
      }
    },

    addScore() {
      this.minusPlayCount();
      this.score += this.scoreArr[this.value];

      let moveScoreArrow =
        this.arrow.x +
        (this.scoreArr[this.value] / 300) * (this.score_bar.width - 30);
      gsap.to(this.arrow, {
        pixi: { x: moveScoreArrow },
        duration: 0.6,
        onComplete: this.initBeer,
      });    
    },
    initBeer() {
      if (this.playCount != 0) {
        this.btn_press.interactive = true;
        this.btn_press.buttonMode = true;
        this.btn_press.alpha = 1;
        this.beer.x = 165;
        this.beer.y = 415;
        this.beer.rotation = 0;
        this.powerCount = 0;
      } else {
        if (this.score < 200) {
          this.failed = true;
          this.onComplete()
        } else {
          this.failed = false;
          this.oncesuccess = true;
          this.onComplete();
        }
      }
    },
    initGame() {
      this.playCount = 3;
      this.initBeer();
      this.beer_remain_1.visible = true;
      this.beer_remain_2.visible = true;
      this.beer_remain_3.visible = true;
      this.score = 0;
      this.arrow.x = 1190;
    },
    nextState() {
      this.pop.gameState++;
    },
    onComplete() {
      let gameState = this.failed ? "gameFailed" : "gameEnd";
      this.pop = {
        PopStep: gameState,
        gameType: "PushBottle",
        status: true,
      };
    },
    closePop() {
      this.pop.status = false;
      // this.popData.status = true;
      // this.popData.text =
      //   "一起來陪鬱悶的陽子喝酒玩遊戲吧。<br>請控制你的力道，把酒杯推到適當的位置。<br>只有分數累積到「斷片」，陽子才能暫時忘記她的煩惱。<br>可別把酒杯打翻了，要是玻璃碎了、發生什麼事情就麻煩了。<br>來吧，和陽子一起喝到斷片！";
    },
    setClose() {
      let vm = this;
      this.close = new PIXI.Sprite(this.loader.resources["close"].texture);
      this.close.x = 50;
      this.close.y = 35;
      this.close.interactive = true;
      this.close.on("pointerdown", this.back);
      this.container.addChild(this.close);
    },
    back() {
      sound.stop("my-sound");
      if (this.oncesuccess) {
        this.$emit("back", 3); //3: PushBottle
      } else {
        this.$emit("back", 6); //6: Failed
      }
      for (var i = this.app.stage.children.length - 1; i >= 0; i--) {
        this.app.stage.children[i].destroy({
          texture: true,
          baseTexture: true,
        });
      }
    },
    again() {
      this.initGame();
      this.pop = {
        PopStep: "game",
        gameType: "PushBottle",
        status: false,
      };
    },
  },
  destroyed() {
    window.removeEventListener("keydown", this.keyDown);
    window.removeEventListener("keyup", this.keyUp);
  },
};
</script>
<style lang="scss" scoped>
.pushBottle {
  canvas {
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
