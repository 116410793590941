import { render, staticRenderFns } from "./Character.vue?vue&type=template&id=11a3e412&scoped=true&"
import script from "./Character.vue?vue&type=script&lang=js&"
export * from "./Character.vue?vue&type=script&lang=js&"
import style0 from "./Character.vue?vue&type=style&index=0&id=11a3e412&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11a3e412",
  null
  
)

export default component.exports