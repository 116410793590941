<template>
    <div class="controlHelp">
        <div class="tabWrap">
            <div class="tab btn" :class="{active:type=='desktop'}" @click="type='desktop'">
                <img src="@/assets/img/pop/btn_help_web.svg" draggable="false" alt="">
                <img src="@/assets/img/pop/btn_hover_help_web.svg" draggable="false" alt="">
            </div>
            <div class="tab btn" :class="{active:type=='mobile'}" @click="type='mobile'">
                <img src="@/assets/img/pop/btn_help_mobile.svg" draggable="false" alt="">
                <img src="@/assets/img/pop/btn_hover_help_mobile.svg" draggable="false" alt="">
            </div>
        </div>
        <div class="container">
            <div class="column" v-for="(item,index) in data[type]" :key="index">
                <img :src="item.img" draggable="false" alt="">
                <p>{{item.text}}</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'ControlHelp',
    data:()=>({
        type:'desktop',
        data:{
            desktop:[
                {
                    img:`${require(`@/assets/img/pop/desktopL.png`)}`,
                    text:'使用鍵盤方向鍵來移動角色'
                },
                {
                    img:`${require(`@/assets/img/pop/desktopR.png`)}`,
                    text:'使用空白鍵來互動'
                }
            ],
            mobile:[
                {
                    img:`${require(`@/assets/img/pop/mobileL.png`)}`,
                    text:'滑動搖桿來移動角色'
                },
                {
                    img:`${require(`@/assets/img/pop/mobileR.png`)}`,
                    text:'點擊TAP來互動'
                }
            ]
        }

        
    }),
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variable.scss";
@import "@/assets/scss/_media.scss";
.controlHelp{
    .tabWrap{
        display:flex;
        justify-content: center;
        margin-bottom:2%;
        .tab{
            width: 16%;
            margin: 0 1%;
            &.active,&:hover{
                img{
                    &:nth-of-type(1){
                        display: none;
                    }
                    &:nth-of-type(2){
                        display: block;
                    }
                }
            }
            img{
                &:nth-of-type(2){
                    display: none;
                }
            }
        }
    }
    .container{
        display:flex;
        justify-content: center;
        width: 82%;
        margin: 0 auto;
        border-radius: 11px;
        overflow: hidden;
        .column{
            width:50%;
            position:relative;
            p{
                position:absolute;
                bottom:5%;
                color:#5E94F1;
                @include absXcenter;
                font-size: 22px;
                width: 100%;
                text-align: center;
                @include multiBreakpoint(tabletS,phone){
                    font-size:16px;
                }
            }
        }
    }
}
</style>