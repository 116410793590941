<template>
    <div class="gamefailed">
        <p>陽子： 我只是想喝醉也做不到嗎嗚嗚嗚，能陪我再玩一次嗎？<br><span style="color:#B8B8B8;">*請別放棄找尋關鍵物品的機會</span></p>
        <img src="@/assets/img/collection/failed.svg" draggable="false">
        <div class="btnWrap">
            <div class="btnL" @click="again">
                <img src="@/assets/img/pop/playAgin.svg" draggable="false">
            </div>
            <div class="btnR" @click="back">
                <img src="@/assets/img/pop/backToTown.svg" draggable="false">
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'GameFailed',
    data:()=>({

    }),
    props:{
        gameType:{
            default:''
        }
    },
    computed:{
    },
    methods:{
        again(){
            this.$emit('again');
        },
        back(){
            this.$emit('back');
        }
    }
}
</script>
<style lang="scss">
@import "@/assets/scss/_media.scss";
.gamefailed{
    position:absolute;
    top:28%;
    width: 47%;
    left:50%;
    transform:translateX(-50%);
    height: 62%;
    >img{
        width: 33%;
        margin: 0 auto;
    }
    p{

        letter-spacing: 0.1em;
        text-align: center;
        overflow:hidden;
        font-size:21px;
        padding-top: 5px;
        @include multiBreakpoint(tabletS,phone){
            font-size: 15px;
        }
        &:nth-of-type(2){
            text-align:center;
        }
        &::-webkit-scrollbar {
            width: 5px;
                // margin-right: 5px;
        }
        &::-webkit-scrollbar-track {
                //margin:5px 5px 5px 0;
                //width: 5px;
            background: transparent;
            border: none;
        }
        &::-webkit-scrollbar-thumb {
            -webkit-border-radius: 100px;
            border-radius: 100px;
            background: #ADF3F9;
                //width: 10px;
        }
    }
    .btnWrap{
        display:flex;
        position: absolute;
        bottom: 0;
        justify-content: center;
        .btnL,.btnR{
            cursor:pointer;
            width: 40%;
            margin:0 2%;
        }
    }
}
</style>