<template>
    <div class="faq">
        <VueFaqAccordion 
            :items="myItems" :activeColor="activeColor" :borderColor="borderColor"
        />
    </div>
</template>
<script>
import VueFaqAccordion from 'vue-faq-accordion'

export default {
    name:'FAQ',
    data:()=>({
        myItems: [
          {
            title: '來自天神的訊息',
            value: '各位玩家，你們好，我是天神。歡迎來到我所創造的小鎮，中正市。這裡環境優美，居民友善，所有的一切在我的安排下都是那麼的完美......只可惜，出了一點點的小差錯。<br>有位名叫陽子的女性想要離開這裡。<br>她已經做了決定，要在10月30日那一天，要讓自己的生命就此停止。<br>這是多麼令人害怕的事情，我們得阻止悲劇發生。<br>在那天來到之前，請你悄悄潛入中正市，與其中的居民對話，從中試著拼湊出所謂的真相......<br>完成我給予的任務，蒐集線索與物品，並留下你的資訊。<br>被我選中的人，將有機會在10月30日當天晚上親自造訪小鎮。<br><br>▷請化身為小鎮中的居民，完成六個關鍵任務<br>▷將蒐集完成的六個物件及線索，放置於小鎮廣場交給天神<br>▷完成所有任務後於10月24日23:59前留下資料，你將有機會親身造訪小鎮，參與10月30日的現場演出<br>',
          },
          {
            title: 'Q1 什麼是《神不在的小鎮》？',
            value: '《神不在的小鎮》是一場結合網路線上直播﹑沉浸式劇場以及電玩虛擬實境的新型態展演。透過戲劇、舞蹈、流行音樂、新媒體裝置及5G科技等跨界元素結合，加上動態捕捉技術與異地共演，打造多視角的觀演方式，創造「網路的沈浸式劇場」。90分鐘的演出，1個故事，3種視角，8位角色，透過二維世界的直播獨白、三維世界的演出現場及四維世界的虛擬空間，觀眾將從中找出線索，並試圖還原劇情事件的真相。',
          },
          {
            title: 'Q2 如何參加及報名？',
            value: '本節目為免費線上演出，觀眾只要在10月30日（六）19:30進入本節目官網即可觀看。',
          },
          {
            title: 'Q3 我要去哪裡觀看演出？',
            value: '演出當天進入本節目官網即可觀看。當天歡迎提早登入頁面，以獲得更多重要線索及彩蛋！',
          },
          {
            title: 'Q4 我想知道演出多長？以及場次時間？',
            value: '演出全長90分鐘，演出時間為10月30日（六）19:30，當夜限定，僅此一場。',
          },
          {
            title: 'Q5 遲到怎麼辦？可以中途離場嗎？',
            value: '演出將於10月30日（六）19:30準時開始，過程中可以自由進出。但LIVE直播錯過就沒有了！',
          },
          {
            title: 'Q6 我可以在演出結束後重複觀看嗎？',
            value: '三個世界的錄影畫面將於演出結束後上傳至兩廳院YouTube並限時回放至11月30日23:59。但回放影片無法參與留言互動和改變劇情走向，請把握演出當下以獲得最佳體驗！',
          },
          {
            title: 'Q7 我想知道卡司有誰？',
            value: '《神不在的小鎮》由兩廳院策劃製作，必應創造統籌執行，進港浪製作擔任主要創作團隊，涅所開發 NAXS corp.建置虛擬展演空間，並邀請蕭東意、告五人、小事製作以及神祕嘉賓等40位表演者共同演出。',
          },
          {
            title: 'Q8 互動的方式有哪些？',
            value: '觀眾可透過設置的聊天室與其他觀眾即時互動留言，演出過程中也可以參與隨機事件的投票，決定劇情走向。',
          },
          {
            title: 'Q9 演出內容有任何注意事項嗎？',
            value: '演出部分含裸露、炫光、煙霧效果、抽煙、暴力、巨大聲響等，建議12歲以上觀眾欣賞。',
          },
          {
            title: 'Q9-1 觀看裝置有限制嗎？',
            value: '電腦、平板電腦以及智慧型手機皆可觀看。網速建議至少10Mbps以上（可透過Fast.com檢測）詳細瀏覽器及軟硬體需求詳見兩廳院節目官網。',
          },
          {
            title: 'Q10 除了線上的體驗我想要更沉浸！',
            value: '10月24日23:59之前，只要登入本節目官網並完成任務，就有機會獲得演出當天參與三維世界現場的門票！',
          },
        ],
        activeColor:'#50D2DC',
        borderColor:'#C8C8C8'
    }),
    components: {
        VueFaqAccordion
    },
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_media.scss";
.faq{
    width: 84%;
    font-size: 24px;
    @include multiBreakpoint(tabletS,phone){
        font-size:18px;
    }
    ::v-deep .accordion__title{
        padding: 25px 25px 15px;
        @include multiBreakpoint(tabletS,phone){
            padding: 15px 25px 7px;
        }
    }
    ::v-deep .accordion__value{
        padding: 0 25px 15px 25px;
        text-indent: 1em;
        @include multiBreakpoint(tabletS,phone){
            padding: 0 25px 7px 25px;
        }
    }
    ::v-deep .accordion__toggle-button{
        @include multiBreakpoint(tabletS,phone){
            padding-left: 8px;
            width: 12px;
            height: 12px;
        }
        &:after,&:before{
            @include multiBreakpoint(tabletS,phone){
                top: 0;
            }
        }
    }
}
</style>