<template>
    <div class="move">
        <transition name="el-fade-in-linear">
            <div id="joystick-zone" :class="{moving: joystickMoving===true}"></div>
        </transition>
        <canvas id="myCanvas" ref="myCanvas"></canvas>
    </div>
</template>
<script>
import * as PIXI from "pixi.js";
import * as np from "nipplejs";
export default {
    name:'MoveSample',
    data:()=>({
        app:null,
        keys:{},
        joystickMoving: false,
        joystick: null,
        move: {x:0,y:0}, 
        deltaTime:null,
        loader:null,
        character:null
    }),
    mounted(){
        this.setMediaLoad();
    },
    methods:{
        init(){
            this.setCanvas();   
            this.setKeyboard();
            this.setJoystick();
            this.setCharacter();
        },
        setCanvas(){
            let vm = this
            let myCanvas = this.$refs.myCanvas
            this.app = new PIXI.Application({
                width:1920,         
                height:1080,
                antialias:true,
                transparent:false,
                resolution: 2,
                view:myCanvas
            })
            this.app.renderer.view.style.position = "absolute";
            this.app.renderer.view.style.display = "block";
            this.app.renderer.autoResize = true;
            this.app.renderer.resize(window.innerWidth, window.innerHeight);
            this.app.stage.interactive = true;
            this.app.stage.interactive = true
            this.app.ticker.add(delta => vm.gameLoop(delta))
        },
        setKeyboard(){
            let vm = this;
            window.addEventListener("keydown",vm.keyDown);
            window.addEventListener("keyup",vm.keyUp);
        },
        setJoystick(){
            let initPos = {
                x: 0.0, y: 0.0
            }
            this.joystick = np.create({
                zone: document.getElementById("joystick-zone"),
                mode: 'dynamic',
                position: { left: '75px', bottom: '75px' },
                size: '150',
                restOpacity: 0.7,
                fadeTime: 0,
            });
            this.joystick.on('dir:up  dir:left  dir:down  dir:right',
                function(evt, data) {
                    console.log(evt.type);
                }
            )
            .on('start', (evt, data)=>{
                initPos.x = data.position.x;
                initPos.y = data.position.y;
                console.log(evt.type);
            })
            .on('move', (evt, data)=>{
                this.move.x = (data.position.x - initPos.x) / 20 * this.deltaTime;
                this.move.y = (data.position.y - initPos.y) / 20 * this.deltaTime;
            })
            .on('end', (evt, data)=>{
                this.move = {
                    x:0,y:0
                }
            })
            window.joystick = this.joystick;
        },
        setMediaLoad(){
            this.loader = new PIXI.Loader;
            this.loader.reset();
            this.loader.add([{name:"babyBlackSheet",url:'/babyBlack/babyBlack.json'}]).load(this.init);
        },
        setCharacter(){
            let characterSheet = this.loader.resources.babyBlackSheet.spritesheet
            this.character = new PIXI.AnimatedSprite(characterSheet.animations[`babyBlack`]);
            this.character.play();
            this.character.animationSpeed = 0.1
            this.character.width = 100;
            this.character.height = 100;
            this.app.stage.addChild(this.character);
        },
        keyDown(e){
            this.keys[e.keyCode] = true;
        },
        keyUp(e){
            this.keys[e.keyCode] = false;
        },
        gameLoop(delta){
            this.deltaTime = delta;
            this.play(delta);
            this.joystickMoving = (this.move.x||this.move.y)?true:false;
            this.joystick.options.size = window.innerWidth < 640 ? '90':'150';
            if(this.move.x||this.move.y){
                this.character.x+=this.move.x;
                this.character.y+=this.move.y;
            }
            //W
            if(this.keys['87']||this.keys['38']){
                this.characterMove('W');
            }
            //A
            if(this.keys['65']||this.keys['37']){
                this.characterMove('A')
            }
            //S
            if(this.keys['83']||this.keys['40']){
                this.characterMove('S')
            }
            //D
            if(this.keys['68']||this.keys['39']){
                this.characterMove('D')
            }
        },
        play(delta){

        },
        characterMove(direction){
            const moveGap = 1 * this.deltaTime
            if(direction=='W'){
                this.character.y-= moveGap;
            }else if(direction=='A'){
                this.character.x-= moveGap;
            }
            else if(direction=='S'){
                this.character.y+= moveGap;
            }else if(direction=='D'){
                this.character.x+= moveGap;
            }
        }
    },
    destroyed(){
        if(this.joystick){
            this.joystick.destroy();
        }
    }
}
</script>
<style lang="scss" scoped>
canvas{
    height:100vh;
}
</style>