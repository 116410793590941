<template>
  <div class="finding">
    <canvas id="myCanvas" ref="myCanvas"></canvas>
    <PopUp
      v-if="pop.status"
      :PopStep="pop.PopStep"
      @closePop="closePop"
      :gameType="pop.gameType"
      @nextState="nextState"
      :gameState="pop.gameState"
      @back="back"
      @again="again"
    />
  </div>
</template>
<script>
import PopUp from "@/components/PopUp.vue";
import * as PIXI from "pixi.js";
import { gsap } from "gsap";
import { PixiPlugin } from "gsap/PixiPlugin";
import { OutlineFilter } from "@pixi/filter-outline";
import { sound } from "@pixi/sound";
export default {
  name: "finding",
  data: () => ({
    app: null,
    keys: {},
    deltaTime: null,
    loader: null,
    pop: {
      PopStep: "game",
      gameType: "Finding",
      status: true,
      gameState: 0,
    },
  }),
  mounted() {
    this.setMediaLoad();
  },
  components: { PopUp },
  methods: {
    init() {
      this.setCanvas();
      this.setKeyboard();
      this.setElement();
    },
    setCanvas() {
      let vm = this;
      let myCanvas = this.$refs.myCanvas;
      this.app = new PIXI.Application({
        width: 1920,
        height: 1080,
        antialias: true,
        transparent: false,
        resolution: 2,
        view: myCanvas,
      });
      this.app.renderer.view.style.position = "absolute";
      this.app.renderer.view.style.display = "block";
      this.app.renderer.autoResize = true;
      if (window.matchMedia("(orientation: portrait)").matches) {
        this.app.renderer.resize(window.innerHeight, window.innerWidth);
        this.windowWidth = window.innerHeight;
        this.windowHeight = window.innerWidth;
      } else {
        this.app.renderer.resize(window.innerWidth, window.innerHeight);
        this.windowWidth = window.innerWidth;
        this.windowHeight = window.innerHeight;
      }
      this.app.stage.interactive = true;
      this.app.ticker.add((delta) => vm.gameLoop(delta));

      gsap.registerPlugin(PixiPlugin);
      PixiPlugin.registerPIXI(PIXI);

      sound.add("my-sound", "/game/finding/music.mp3");
      sound.play("my-sound", { loop: true });
    },
    setKeyboard() {
      let vm = this;
      window.addEventListener("keydown", vm.keyDown);
      window.addEventListener("keyup", vm.keyUp);
    },
    setMediaLoad() {
      this.loader = new PIXI.Loader();
      this.loader.reset();
      this.loader
        .add([
          { name: "sheet", url: "/game/finding/1.json" },
          { name: "fSheet", url: "/game/finding/2.json" },
          { name: "close", url: "./game/close.png" },
        ])
        .load(this.init);
    },
    keyDown(e) {
      this.keys[e.keyCode] = true;
    },
    keyUp(e) {
      this.keys[e.keyCode] = false;
    },
    setElement() {
      const defaultIcon = "url('./game/finding/magnifier.png'),auto";
      this.app.renderer.plugins.interaction.cursorStyles.default = defaultIcon;
      let sheet = this.loader.resources.sheet.spritesheet;
      let fSheet = this.loader.resources.fSheet.spritesheet;

      this.bg = new PIXI.Sprite(sheet.textures["bg.png"]);
      this.book = new PIXI.Sprite(sheet.textures["book.png"]);
      this.card_fruitbasket = new PIXI.Sprite(
        sheet.textures["card-fruitbasket.png"]
      );
      this.card_phone = new PIXI.Sprite(sheet.textures["card-phone.png"]);
      this.card_whitepowder = new PIXI.Sprite(
        fSheet.textures["card-whitepowder.png"]
      );
      this.chair = new PIXI.Sprite(sheet.textures["chair.png"]);
      this.computer = new PIXI.Sprite(sheet.textures["computer.png"]);
      this.desk = new PIXI.Sprite(sheet.textures["desk.png"]);
      this.hint = new PIXI.Sprite(sheet.textures["hint.png"]);
      this.hint_bar = new PIXI.Sprite(sheet.textures["hint_bar.png"]);
      this.hint_bottom = new PIXI.Sprite(sheet.textures["hint_bottom.png"]);
      this.mug = new PIXI.Sprite(sheet.textures["mug.png"]);
      this.pen = new PIXI.Sprite(sheet.textures["pen.png"]);
      this.pencil = new PIXI.Sprite(sheet.textures["pencil.png"]);

      this.hintCountArr = [
        sheet.textures["0.png"],
        sheet.textures["1.png"],
        sheet.textures["2.png"],
        sheet.textures["3.png"],
      ];

      this.hintCount = new PIXI.Sprite(this.hintCountArr[0]);

      this.fruitbasket = new PIXI.AnimatedSprite(
        sheet.animations[`fruitbasket`]
      );
      this.fruitbasket.animationSpeed = 0.02;

      this.phone = new PIXI.AnimatedSprite(sheet.animations[`phone`]);
      this.phone.animationSpeed = 0.01;

      this.whitepowder = new PIXI.AnimatedSprite(
        sheet.animations[`whitepowder`]
      );
      this.whitepowder.animationSpeed = 0.05;

      this.hoverElArr = [
        { sprite: this.book, position: [1022, 878] },
        { sprite: this.computer, position: [237, 295] },
        { sprite: this.mug, position: [490, 432] },
        { sprite: this.pen, position: [575, 617] },
        { sprite: this.pencil, position: [63, 705] },
        { sprite: this.chair, position: [178, 637] },
      ];

      this.clickElArr = [
        {
          animated: this.fruitbasket,
          position: [575, 312],
          card: this.card_fruitbasket,
          found: false,
        },
        {
          animated: this.phone,
          position: [828, 538],
          card: this.card_phone,
          found: false,
        },
        {
          animated: this.whitepowder,
          position: [175, 464],
          card: this.card_whitepowder,
          found: false,
        },
      ];

      this.desk.x = 136;
      this.desk.y = 394;
      this.hint_bottom.x = 802;
      this.hint_bottom.y = 55;
      this.hint_bar.x = 802;
      this.hint_bar.y = 55;
      this.hint_bar_Width = this.hint_bar.width / 3;
      this.hint_bar.width = this.hint_bar_Width * 0;
      this.hint.x = 786;
      this.hint.y = 39;
      this.hintCount.x = 950;
      this.hintCount.y = 69;
      this.container = new PIXI.Container();
      this.container.addChild(
        this.bg,
        this.desk,
        this.hint_bottom,
        this.hint_bar,
        this.hint,
        this.hintCount
      );

      this.hoverElArr.forEach((element, index) => {
        element.sprite.interactive = true;
        element.sprite.x = element.position[0];
        element.sprite.y = element.position[1];
        element.sprite
          .on("pointerover", function () {
            this.filters = [new OutlineFilter(3, 0x4cffff)];
            this.y = element.position[1] - 5;
          })
          .on("pointerout", function () {
            this.filters = null;
            this.y = element.position[1];
          });
        this.container.addChild(element.sprite);
      });

      this.overlay = new PIXI.Graphics();
      this.overlay.beginFill(0x000000);
      this.overlay.lineStyle(0, 0x000000);
      this.overlay.drawRect(0, 0, this.bg.width, this.bg.height);
      this.overlay.alpha = 0.3;
      this.overlay.visible = false;
      this.overlay.interactive = true;
      this.overlay.on("pointerdown", this.offCard);

      this.clickElArr.forEach((element) => {
        let overlay = this.overlay;
        element.animated.interactive = true;
        element.animated.x = element.position[0];
        element.animated.y = element.position[1];
        element.animated.play();
        element.animated
          .on("pointerover", function () {
            this.filters = [new OutlineFilter(3, 0x4cffff)];
            this.y = element.position[1] - 5;
          })
          .on("pointerout", function () {
            this.filters = null;
            this.y = element.position[1];
          })
          .on("pointerdown", function () {
            element.card.visible = true;
            element.found = true;
            overlay.visible = true;
          });
        this.container.addChild(element.animated);
      });

      this.container.addChild(this.overlay);

      this.clickElArr.forEach((element) => {
        element.card.visible = false;
        element.card.x = 664;
        element.card.y = 227;
        this.container.addChild(element.card);
      });

      this.ratio = this.bg.height / this.bg.width;
      this.windowRatio = window.innerHeight / window.innerWidth;

      if (this.ratio < this.windowRatio) {
        this.container.width = window.innerWidth;
        this.container.height = this.container.width * this.ratio;
      } else {
        this.container.height = window.innerHeight;
        this.container.width = this.container.height / this.ratio;
      }

      this.oncesuccess = false;
      this.setClose();
      this.app.stage.addChild(this.container);
      this.app.stage.pivot.set(
        -(window.innerWidth - this.container.width) / 2,
        0
      );
    },
    offCard() {
      let count = 0;
      this.clickElArr.forEach((e) => {
        e.card.visible = false;
        this.overlay.visible = false;
        if (e.found) {
          count++;
        }
      });

      this.hint_bar.width = this.hint_bar_Width * count;
      this.hintCount.texture = this.hintCountArr[count];

      if (count == 3) {
        this.onComplete();
      }
    },
    finishGame() {
      this.closePop();
    },
    keyDown(e) {
      this.keys[e.keyCode] = true;
    },
    keyUp(e) {
      this.keys[e.keyCode] = false;
    },
    gameLoop(delta) {
      this.deltaTime = delta;
      this.play(delta);

      if (this.overlay.visible) {
        if (this.keys["32"]) {
          this.offCard();
        }
      }
    },
    initGame() {
      this.clickElArr.forEach((e) => {
        e.found = false;
      });

      this.hint_bar.width = this.hint_bar_Width * 0;
      this.hintCount.texture = this.hintCountArr[0];
    },
    play(delta) {},
    nextState() {
      this.pop.gameState++;
    },
    onComplete() {
      this.oncesuccess = true;
      this.pop = {
        PopStep: "gameEnd",
        gameType: "Finding",
        status: true,
      };
    },
    closePop() {
      this.pop.status = false;
    },
    setClose() {
      this.close = new PIXI.Sprite(this.loader.resources["close"].texture);
      this.close.x = 50;
      this.close.y = 50;
      this.close.interactive = true;
      this.close.on("pointerdown", this.back);
      this.container.addChild(this.close);
    },
    back() {
      sound.stop("my-sound");
      if (this.oncesuccess) {
        this.$emit("back", 0); //0: Finding
      } else {
        this.$emit("back", 6); // 6: Failed
      }

      for (var i = this.app.stage.children.length - 1; i >= 0; i--) {
        this.app.stage.children[i].destroy({
          texture: true,
          baseTexture: true,
        });
      }
    },
    again() {
      this.initGame();
      this.pop = {
        PopStep: "game",
        gameType: "Finding",
        status: false,
      };
    },
  },
  destroyed() {},
};
</script>
<style lang="scss" scoped>
.finding {
  canvas {
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>