<template>
  <div class="gamechat" :class="{ placeTip: !popData.gameType }">
    <div class="container" :class="[popData.avatar]">
      <div
        class="btnWrap"
        @click="submit"
        v-if="popData.gameType == 'cardgame' && step == 2"
      >
        <div>
          <img src="@/assets/img/chat/btn_check.svg" alt="" draggable="false" />
          <img src="@/assets/img/chat/btn_check_press.svg" alt="" draggable="false" />
        </div>
      </div>
      <div
        class="btnWrap"
        @click="complete"
        v-if="popData.gameType == 'cardgame' && step == 3"
      >
        <div>
          <img src="@/assets/img/chat/btn_enddivine.svg" alt="" draggable="false"  />
          <img src="@/assets/img/chat/btn_enddivine_press.svg" alt="" draggable="false" />
        </div>
      </div>
      <div class="main" :class="{cardgame:popData.gameType=='cardgame',blue:popData.result=='blue',green:popData.result=='green',yellow:popData.result=='yellow'}">
        <img :src="avatarImg" class="avatar" alt="" v-if="avatarImg != ''"  draggable="false"/>
        <div class="text" v-if="popData.text">
          <p>{{ popData.name }}</p>
          <vue-typer
            :text="popData.text"
            :repeat="0"
            @completed="onComplete"
          ></vue-typer>
        </div>
        <img
          v-if="cardgametap"
          class="btn_space desktop"
          src="@/assets/img/chat/space.png"
          alt=""
          draggable="false"
        />
        <img
          v-if="cardgametap"
          class="btn_space mobile"
          src="@/assets/img/chat/tap.png"
          alt=""
          @click="tapping"
          draggable="false"
        />
      </div>
    </div>
    <!-- <div class="btnWrap">
            <div class="btnOne btn" v-visible="popData[name].btn.one[chatStep]!=''">
                <img src="@/assets/img/chat/btn.svg" alt="">
                <img src="@/assets/img/chat/btn_press.svg" alt="">
                <span>{{popData[name].btn.one[chatStep]}}</span>
            </div>
            <div class="btnTwo btn" @click="nextStep">
                <img src="@/assets/img/chat/btn.svg" alt="">
                <img src="@/assets/img/chat/btn_press.svg" alt="">
                <span>{{popData[name].btn.two[chatStep]}}</span>
            </div>
        </div> -->
  </div>
</template>
<script>
import { VueTyper } from "vue-typer";
export default {
  nane: "gamechat",
  data: () => ({}),
  props: {
    popData: {
      default: {},
    },
    step: { 
      default: 0,
    },
  },
  components: { VueTyper },
  computed: {
    avatarImg() {
      if (this.popData.avatar) {
        return `${require(`@/assets/img/chat/avatar/${this.popData.avatar}.svg`)}`;
      } else {
        return "";
      }
    },
    cardgametap(){
      if(this.popData.text == '天啊...是死神牌！！你....需要我特製的「祝福糖漿」來替你消災解厄。')return true;
      else return false
    }
  },
  methods: {
    complete() {
      this.$emit("completed");
    },
    submit() {
      this.$emit("submit");
    },
    onComplete() {
      this.$emit("chatComplete");
    },
    tapping(){
      this.$emit('chattap')
    }
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variable.scss";
@import "@/assets/scss/_media.scss";
.mobile{
    display: none !important;
    @include multiBreakpoint(tabletS, phone) {
      display: block !important;
    }
}
.desktop{
  @include multiBreakpoint(tabletS, phone) {
    display: none !important;
  }
}
.gamechat {
  position: fixed;
  bottom: 5%;
  width: 40%;
  @include absXcenter;
  display: flex;
  justify-content: space-between;
  width: 557px;
  @include multiBreakpoint(tabletS, phone) {
    // width:215px;
    width: 340px;
  }
  &.placeTip{
    .container {
      background-image: url("~@/assets/img/chat/chatBox_blue.png");
      .main{
        .text p{
          color:#3282B4;
          text-align: center;
          margin-bottom: 5px;
        }
        .text{
          width: 100%;
          ::v-deep .custom.char.typed{
            color:white;
          }
        }
      }
    }
  }
  .container {
    background-size: 100%;
    background-image: url("~@/assets/img/chat/chatBox.png");
    background-repeat: no-repeat;
    //padding: 4%;
    //width:570px;
    position: relative;
    padding-bottom: 21%;
    padding-bottom: 124px;
    width: 100%;
    @include multiBreakpoint(tabletS, phone) {
      padding-bottom: 71px;
    }
    .main {
      position: absolute;
      width: 100%;
      padding: 0 4%;
      display: flex;
      box-sizing: border-box;
      @include absYcenter;
      &.cardgame{
        padding: 0 8% 0 4%;
      }
      &.blue{
        ::v-deep .custom.char.typed{
          &:nth-of-type(6),&:nth-of-type(7){
            color:#50CCF4;
          }
        }
      }
      &.green{
        ::v-deep .custom.char.typed{
          &:nth-of-type(6),&:nth-of-type(7){
            color:#3EE2BB;
          }
        
        }
      }
      &.yellow{
        ::v-deep .custom.char.typed{
          &:nth-of-type(6),&:nth-of-type(7){
            color:#FFD912;
          }
        }
      }
      .avatar {
        width: 14.5%;
        margin-right: 2%;
        align-self: flex-start;
      }
      .text {
        width: 85.5%;
        p {
          font-size: 26px;
          color: #1850b7;
          @include multiBreakpoint(tabletS, phone) {
            font-size: 17px;
          }
        }
      }
      .vue-typer {
        font-size: 26px;
        @include multiBreakpoint(tabletS, phone) {
          font-size: 17px;
        }
      }
    }
    // &.sunny{
    //     .main{
    //         .vue-typer{
    //             ::v-deep .custom.char.typed{
    //                 &:nth-of-type(24){
    //                     padding-right:12%;
    //                 }
    //             }
    //         }
    //     }
    // }
    .btn_space {
      position: absolute;
      width: 6%;
      @include absYcenter;
      right: 10px;
      /* animation 參數設定 */
      animation-name: bouse; /*動畫名稱，需與 keyframe 名稱對應*/
      animation-duration: 0.5s; /*動畫持續時間，單位為秒*/
      animation-delay: 0.5s; /*動畫延遲開始時間*/
      animation-iteration-count: infinite; /*動畫次數，infinite 為無限次*/
      animation-direction: alternate;
      transition: ease-in-out;
      &.mobile{
        width: 5%;
        display: none !important;
        @include multiBreakpoint(tabletS, phone) {
          display: block !important;
        }
      }
      &.desktop{
        @include multiBreakpoint(tabletS, phone) {
          display: none !important;
        }
      }
    }
    @keyframes bouse {
      0% {
        transform: translateY(0);
      }
      100% {
        transform: translateY(-10px);
      }
    }
  }
  .btnWrap {
    width: 20%;
    position: absolute;
    top: -48%;
    @include absXcenter;
    > div {
      position: relative;
      cursor: pointer;
      img {
        &:nth-of-type(2) {
          display: none;
        }
      }
      &:hover {
        img {
          &:nth-of-type(1) {
            display: none;
          }
          &:nth-of-type(2) {
            display: block;
          }
        }
      }
      span {
        position: absolute;
        @include abscenter;
        color: #0572a4;
        top: 60%;
        font-size: 25px;
        width: 100%;
        text-align: center;
        @include multiBreakpoint(tabletS, phone) {
          font-size: 17px;
        }
      }
    }
  }
}
</style>