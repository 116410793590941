<template>
    <div class="character">
        <div class="column btn" @click="setSelect(index)" v-for="(item,index) in characterData" :key="index" :class="{active:select==index}">
            <img :src="item.normal" alt="" draggable="false">
            <img :src="item.selected" alt="" draggable="false">
        </div>
    </div>
</template>
<script>
export default {
    name:'Character',
    data:()=>({
        characterData:[
            {
                normal:require('@/assets/img/pop/character_1.svg'),
                selected:require('@/assets/img/pop/character_select_1.svg'),
            },
            {
                normal:require('@/assets/img/pop/character_2.svg'),
                selected:require('@/assets/img/pop/character_select_2.svg'),
            },
            {
                normal:require('@/assets/img/pop/character_3.svg'),
                selected:require('@/assets/img/pop/character_select_3.svg'),
            }
        ]
    }),
    props:{
        select:{
            default:0
        }
    },
    methods:{
        setSelect(idx){
            this.$emit('setSelect',idx)
        }
    }    
}
</script>
<style lang="scss" scoped>
.character{
    display:flex;
    width: 78%;
    justify-content: space-between;

    .column{
        width:32.5%;
        img{
            &:nth-of-type(2){
                display:none;
            }
        }
        &.active,&:hover{
            img{
                &:nth-of-type(2){
                    display:block;
                }
                &:nth-of-type(1){
                    display:none;
                }
            }
        }
    }
}
</style>