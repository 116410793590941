<template>
    <div class="gameresult">
        <p>{{dataText.decription}}</p>
        <img :src="award" draggable="false">
        <p>獲得關鍵物品 <span style="color:#FFB359;">{{dataText.award}}</span></p>
        <div class="btnWrap">
            <div class="btnL" @click="again">
                <img src="@/assets/img/pop/playAgin.svg" draggable="false">
            </div>
            <div class="btnR" @click="back">
                <img src="@/assets/img/pop/backToTown.svg" draggable="false">
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:'GameResult',
    data:()=>({

    }),
    props:{
        gameType:{
            default:''
        }
    },
    computed:{
        award(){
            switch (this.gameType) {
                case 'CardGame':
                    return `${require(`@/assets/img/collection/drug.svg`)}`;
                    break;
                case 'DonateGame':
                    return `${require(`@/assets/img/collection/underwear.svg`)}`;
                    break;
                case 'CleanStains':
                    return `${require(`@/assets/img/collection/hair.svg`)}` 
                    break;
                case 'PushBottle':
                    return `${require(`@/assets/img/collection/cd.svg`)}` 
                    break;
                case 'Finding':
                    return `${require(`@/assets/img/collection/fruit.svg`)}` 
                    break;
                case 'Horror':
                    return `${require(`@/assets/img/collection/mouse.svg`)}` 
                    break;
                default:
                    return ''
                    break;
            }
        },
        dataText(){
            switch (this.gameType) {
                case 'CardGame':
                    return {decription:'彗星夫人： 帶著我的祝福遠離災厄吧，嘻嘻嘻...',award:'祝福糖漿'};
                    break;
                case 'CleanStains':
                    return {decription:'這是誰留在牆上的字，太過份吧了! 難怪會被潑漆......',award:'遺留的假髮'};
                    break;
                case 'DonateGame':
                    return {decription:'沛沛： 感謝大大熱情贊助！　我就把剛穿過的．．．送給你吧!',award:'原味內褲'};
                    break;
                case 'PushBottle':
                    return {decription:'LUCY： 謝謝你陪陽子喝酒，雖然不是厲害的東西，還是請你當謝禮收下吧。',award:'簽名專輯'};
                    break;
                case 'Finding':
                    return {decription:'這籃子好重！ 咦？ 這閃閃亮亮的該不會是黃金...',award:'水果籃'};
                    break;
                case 'Horror':
                    return {decription:'RUN： 哇!你蠻有種的嘛，其中一隻老鼠就送你當紀念吧。',award:'惡作劇死老鼠'};
                    break;
                default:
                    return ''
                    break;
            }
        }
    },
    methods:{
        again(){
            this.$emit('again');
        },
        back(){
            this.$emit('back');
        }
    }
}
</script>
<style lang="scss">
@import "@/assets/scss/_media.scss";
.gameresult{
    position:absolute;
    top:28%;
    width: 47%;
    left:50%;
    transform:translateX(-50%);
    height: 62%;
    >img{
        width: 33%;
        margin: 0 auto;
    }
    p{

        letter-spacing: 0.1em;
        text-align: center;
        overflow:hidden;
        font-size:21px;
        padding-top: 5px;
        @include multiBreakpoint(tabletS,phone){
            font-size: 15px;
        }
        &:nth-of-type(2){
            text-align:center;
        }
        &::-webkit-scrollbar {
            width: 5px;
                // margin-right: 5px;
        }
        &::-webkit-scrollbar-track {
                //margin:5px 5px 5px 0;
                //width: 5px;
            background: transparent;
            border: none;
        }
        &::-webkit-scrollbar-thumb {
            -webkit-border-radius: 100px;
            border-radius: 100px;
            background: #ADF3F9;
                //width: 10px;
        }
    }
    .btnWrap{
        display:flex;
        position: absolute;
        bottom: 0;
        justify-content: center;
        .btnL,.btnR{
            cursor:pointer;
            width: 40%;
            margin:0 2%;
        }
    }
}
</style>