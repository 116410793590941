<template>
  <div class="rotateTip">
    <div class="main">
      <p>遊戲開始前，請先確認裝置設定：</p>
      <p id="iphone13">* iPhone 13 可能無法開啟，建議使用其他裝置 *</p>
      <p>1. 解除鎖定螢幕</p>
      <img src="@/assets/img/unlock.png" alt="" draggable="false" />
      <p>2. 將螢幕轉至橫向</p>
      <img src="@/assets/img/rotation.png" alt="" draggable="false" />
      <p>3. 建議使用瀏覽器開啟</p>
      <img src="@/assets/img/chrome.png" alt="" draggable="false" />
      <img src="@/assets/img/safari.png" alt="" draggable="false" />
    </div>
  </div>
</template>
<script>
export default {
  name: "RotateTip",
};
</script>
<style lang="scss">
.rotateTip {
  position: fixed;
  background-color: #355671;
  z-index: 99999999999;
  // top: 50%;
  // left: 50%;
  //     transform: translate(-50%,-50%) rotate(
  // -90deg);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  display: none;
  .main {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    display: inline-block;
    width: 100%;
    text-align: center;
    img {
      width: 10vw;
      display: inline-block;
      margin: 3vw 2vw 8vw 2vw;
    }
    p {
      text-align: center;
      margin: 0 auto;
      color: #fff;
      font-size: 1.6rem;
      line-height: 2rem;
      letter-spacing: 0.1rem;
    }
    #iphone13 {
      font-size: 1.2rem;
      margin-bottom: 5vw;
    }
  }
}
</style>