<template>
    <div class="collection">
        <ul>
            <li v-for="(item,index) in collectData" :key="index" :class="{show:collectStatus[index]}">
                <img :src="item" alt="">
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    name:'Collection',
    data:()=>({
        collectData:[
            `${require(`@/assets/img/collection/fruit.svg`)}`,
            `${require(`@/assets/img/collection/underwear.svg`)}`,
            `${require(`@/assets/img/collection/drug.svg`)}`,
            `${require(`@/assets/img/collection/cd.svg`)}`,
            `${require(`@/assets/img/collection/mouse.svg`)}`,
            `${require(`@/assets/img/collection/hair.svg`)}`,
        ]
    }),
    props:{
        collectStatus:{
            default:()=>[true,true,true,true,true,true,true]
        }
    },
}
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_media.scss";

.collection{
    background-image:url('~@/assets/img/collection/collectionBg.svg');
    // width: 29%;
    // padding-bottom: 5.7%;
    background-repeat: no-repeat;
    width:417px;
    padding-bottom: 82px;
    background-size: 100%;
    position:relative;
    @include multiBreakpoint(tablet,tabletS){
        width: 320px;
        padding-bottom: 62px;
    }
    @include breakpoint(phone){
        width: 210px;
        padding-bottom: 42px;
    }

    ul{
        position:absolute;
        width: calc(100% - 4px);
        left: 4px;
        display: flex;
        align-items: center;
        height: 100%;
        li{
            width:calc(100% / 6);
            transform: translate(35px, 45px);
            opacity: 0;
            transition-property: all; 
            transition-timing-function:ease;
            transition-duration: 1s;
            &.show{
                transform: translate(0, 0);
                opacity: 1;
            }
            img{
                width:80%;
                margin:0 auto;
            }
        }
    }
}
</style>