<template>
  <div class="pop" style="touch-action: none;" >
    <div class="blackBg" :class="{ game: PopStep == 'game' }"></div>
    <div
      class="main"
      :class="{
        story: PopStep == 'story',
        character: PopStep == 'character',
        faq: PopStep == 'faq',
        story1030: PopStep == 'story1030',
        controlhelp: PopStep == 'controlhelp',
        lottery: PopStep == 'lottery',
        lotteryEnd: PopStep == 'lotteryEnd',
        game:
          PopStep == 'game' || PopStep == 'gameEnd' || PopStep == 'gameFailed',
        collectionend: PopStep == 'collectionend',
      }"
    >
      <img :src="popBg" draggable="false" />
      <h1 v-html="h1Title" :class="{ lotteryH1: this.lotterystep == 1 }"></h1>
      <p
        v-html="storyText"
        v-if="
          PopStep == 'story' ||
          PopStep == 'story1030' ||
          PopStep == 'lotteryEnd'
        "
      ></p>
      <p v-html="storyText" v-if="PopStep == 'game' && gameState == 0"></p>
      <Character
        v-if="PopStep == 'character'"
        :select="characterSelect"
        @setSelect="setSelect"
      />
      <FAQ v-if="PopStep == 'faq'" />
      <ControlHelp v-if="PopStep == 'controlhelp'" />
      <Lottery
        v-if="PopStep == 'lottery'"
        :lotterystep="lotterystep"
        @initLottery="initLottery"
        :success="success"
        @lotterySuccess="lotterySuccess"
        :submitStatus="submitStatus"
        @lotteryFailed="lotteryFailed"
      />
      <GameResult
        :gameType="gameType"
        v-if="PopStep == 'gameEnd'"
        @again="again"
        @back="back"
      />
      <GameFailed v-if="PopStep == 'gameFailed'" @again="again" @back="back" />
      <CollectionEnd v-if="PopStep == 'collectionend'" @closePop="closePop" />
      <div
        class="gameImg"
        v-if="gameTutorial && PopStep == 'game'"
        :class="{ pushBottle: gameType == 'PushBottle' }"
      >
        <img
          v-if="gameType == 'CleanStains'"
          src="@/assets/img/pop/cleanstains.svg"
          alt=""
          draggable="false"
        />
        <img
          v-if="gameType == 'DonateGame'"
          src="@/assets/img/pop/donategame.svg"
          alt=""
          draggable="false"
        />
        <img
          v-if="gameType == 'PushBottle'"
          src="@/assets/img/pop/pushbottle.svg"
          alt=""
          draggable="false"
        />
      </div>
      <div
        class="button btn"
        v-if="
          PopStep != 'faq' &&
          PopStep != 'controlhelp' &&
          PopStep != 'game' &&
          PopStep != 'gameEnd' &&
          PopStep != 'gameFailed' &&
          PopStep != 'collectionend'
        "
        v-show="lotterystep == 2"
        @click="submit"
      >
        <img :src="buttonImg" alt="" draggable="false" />
      </div>
      <div class="button btn" v-if="PopStep == 'character'" @click="submit">
        <img :src="buttonImg" alt="" draggable="false" />
      </div>
      <div class="button btn" v-if="PopStep == 'story'" @click="submit">
        <img :src="buttonImg" alt="" draggable="false" />
      </div>
      <div
        class="button btn"
        v-if="lotterystep == 3 || PopStep == 'lotteryEnd'"
        @click="submit"
      >
        <img
          src="@/assets/img/pop/btn_lotteryEnd.svg"
          draggable="false"
          alt=""
        />
      </div>
      <div
        class="button btn"
        v-if="gameTutorial && PopStep == 'game'"
        @click="submit"
      >
        <img src="@/assets/img/pop/btn_game.svg" alt="" draggable="false" />
      </div>
      <img
        v-if="cat"
        class="cat"
        src="@/assets/img/cat.svg"
        alt=""
        draggable="false"
      />
      <img
        v-if="exit"
        class="btn_exit btn"
        src="@/assets/img/pop/btn_exit.svg"
        alt=""
        @click="closePop"
        draggable="false"
      />
      <img
        v-if="PopStep == 'lottery' && lotterystep == 1"
        class="btn_space desktop"
        src="@/assets/img/pop/space.svg"
        alt=""
        draggable="false"
      />
      <img
        v-if="PopStep == 'lottery' && lotterystep == 1"
        class="btn_space mobile"
        src="@/assets/img/pop/tap.svg"
        draggable="false"
        alt=""
        @click="tapping"
      />
      <!-- <img
        v-if="spaceStatus && PopStep == 'game'"
        class="btn_space desktop"
        src="@/assets/img/pop/space.svg"
        alt=""
      />
      <img
        v-if="spaceStatus && PopStep == 'game'"
        class="btn_space mobile"
        src="@/assets/img/pop/tap.svg"
        alt=""
      /> -->
      <img
        v-if="spaceStatus && PopStep == 'game'"
        class="btn_space desktop"
        src="@/assets/img/pop/space.svg"
        draggable="false"
        alt=""
      />
      <img
        v-if="spaceStatus && PopStep == 'game'"
        class="btn_space mobile"
        src="@/assets/img/pop/tap.svg"
        draggable="false"
        alt=""
        @click="tapping"
      />
      <div class="btnWrap" v-if="PopStep == 'story1030'">
        <div class="btnL btn" @click="goToUrl('live')">
          <img src="@/assets/img/pop/btn_live.png" draggable="false" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Character from "@/components/pop/Character.vue";
import FAQ from "@/components/pop/FAQ.vue";
import ControlHelp from "@/components/pop/ControlHelp.vue";
import Lottery from "@/components/pop/Lottery.vue";
import GameResult from "@/components/pop/GameResult.vue";
import GameFailed from "@/components/pop/GameFailed.vue";
import CollectionEnd from "@/components/pop/CollectionEnd.vue";
export default {
  name: "Pop",
  data: () => ({
    lotterystep: null,
    characterSelect: 0,
    success: false,
    submitStatus: false,
  }),
  components: {
    Character,
    FAQ,
    ControlHelp,
    Lottery,
    GameResult,
    GameFailed,
    CollectionEnd,
  },
  props: {
    bgtype: {
      default: 1,
    },
    PopStep: {
      default: "game",
    },
    cat: {
      default: false,
    },
    exit: {
      default: false,
    },
    gameType: {
      default: "",
    },
    gameState: {
      default: 0,
    },
    props: {
      tapStatus: false,
    },
  },
  mounted() {
    let vm = this;
    window.addEventListener(
      "keydown",
      function (e) {
        if (e.keyCode == 32) {
          vm.tapping();
        }
      },
      false
    );
  },
  computed: {
    popBg() {
      return `${require(`@/assets/img/pop/bg_${this.bgtype}.svg`)}`;
    },
    buttonImg() {
      return `${require(`@/assets/img/pop/btn_${this.PopStep}.svg`)}`;
    },
    h1Title() {
      if (this.PopStep == "story") {
        return "親愛的外來者<br>您有一則來自天神的訊息";
      } else if (this.PopStep == "character") {
        return "選擇你進入小鎮的樣子吧";
      } else if (this.PopStep == "faq") {
        return "關於兩廳院製作《神不在的小鎮》";
      } else if (this.PopStep == "story1030") {
        return "人們恐懼結果，<span style='position:relative;top:-4px;'>神，則害怕原因</span>";
      } else if (this.PopStep == "controlhelp") {
        return "操作教學";
      } else if (this.PopStep == "lottery") {
        if (this.lotterystep == 1) {
          return "恭喜完成任務！<br>留下資料就有機會成為天選之人";
        } else if (this.lotterystep == 2) {
          return "成為天選之人";
        } else {
          return "資料已成功傳送！請靜候佳音";
        }
      } else if (this.PopStep == "lotteryEnd") {
        return "天選之人徵選結束";
      } else if (this.PopStep == "game") {
        return "遊戲說明";
      } else if (this.PopStep == "gameEnd" || this.PopStep == "gameFailed") {
        return "遊戲結束";
      } else if (this.PopStep == "collectionend") {
        return "關鍵物件蒐集完成<br>快到傳送門交給天神吧！";
      }
    },
    storyText() {
      if (this.PopStep == "story") {
        return '各位玩家，你們好，我是<span style="color:#FFB359;">天神</span>。<br>歡迎來到我所創造的小鎮，中正市。這裡環境優美，居民友善，所有的一切在我<br>的安排下都是那麼的完美......只可惜，出了一點點的<span style="color:#FFB359;">小差錯</span>。有位名叫<span style="color:#FFB359;">陽子</span>的女性想要離開這裡。<br>她已經做了決定，在<span style="color:#FFB359;">10月30日</span>那一天，要讓自己的生命就此停止。<br>這是多麼令人害怕的事情，我們得阻止悲劇發生。<br>在那天來到之前，請你悄悄潛入中正市，與其中的居民對話，從中試著拼湊出所謂的真相......<br><br>完成我給予的任務，蒐集線索與物品，並留下你的資訊。<br>被我選中的人，將有機會在<span style="color:#FFB359;">10月30日</span>當天晚上親自造訪小鎮。<br><br>▷請化身為小鎮中的居民，完成<span style="color:#FFB359;">六個關鍵任務</span><br>▷將蒐集完成的<span style="color:#FFB359;">六個物件及線索</span>，放置於<span style="color:#FFB359;">小鎮廣場</span>交給天神<br>▷完成所有任務後於<span style="color:#FFB359;">10月24日23:59前</span>留下資料，你將有機會親身造訪小鎮，參與10月30日的現場演出';
      } else if (this.PopStep == "story1030") {
        return '你要選擇改變結果，還是試著面對原因？<br><br>親愛的玩家，你好，我是天神。<br>我所創造的小鎮，中正市，發生了一場<span style="color:#FFB359;">無法挽回的悲劇</span>。<br>一位名叫<span style="color:#FFB359;">陽子</span>的女性選擇透過自殺，離開我們了。<br>她帶著憂傷與憤恨死去，我不知道誰才是害死她的兇手，也許多一雙眼睛，可以幫助我找到她死去的原因。<br>我在這裡給你們八個名字：<br><br>中正市市長，曾建國。<br>知名占卜師，彗星夫人。<br>創作歌手，Lucy。<br>線上拍賣王，萬寶路。<br>人氣Youtuber，RUN和天屎。<br>美妝部落客，小綠。<br>辣妹直播主，沛沛。<br><br>他們都跟陽子的死有關係，但誰才是<span style="color:#FFB359;">推動命運齒輪的罪人</span>?<br>為了讓你更明智的判斷，我將會讓小鎮的時間重複三次。<br>請凝神聆聽，捕捉話語底下那細微的真相，最後，請你做出屬於你的審判。<br><br>「是誰取消了陽子的存在？」<br><br>你的決定，將會創造他們各自的結局。';
      } else if (this.PopStep == "lotteryEnd") {
        return '儘管很想邀請每一個你到訪，然而小鎮的參訪名額有限。<br>直到<span style="color:#FFB359;">10月30日</span>那天、黑暗的秘密被揭發之前，中正市都將持續開放，請你盡情<br>體驗小鎮生活，並找到故事的蛛絲馬跡吧。';
      } else if (this.PopStep == "game") {
        if (this.gameType == "CardGame") {
          return "請傾聽靈魂的聲音，根據你的內心的直覺，選出你心中真正的聲音所向。<br>請靜心審慎挑選，你的每個決定，將會影響你能得到怎樣的祝福。<br><br>心誠則靈，願你平安";
        } else if (this.gameType == "CleanStains") {
          return "重要的訊息卻被潑漆遮住了，請你協助拼湊出訊息的原貌吧。<br>或許這面牆上，記錄了什麼不為人知的故事也說不定。<br><br>想想辦法，找到隱藏的訊息吧！";
        } else if (this.gameType == "DonateGame") {
          return "請幫沛沛在接下來的直播中衝高流量，<br>刷愛心刷讚、抖內刷起來！<br><br>讓她成為今晚的女王吧！";
        } else if (this.gameType == "game") {
          return "在這一片雜亂之中，請試著找出可能會有幫助的東西吧。<br>每一個物品背後的故事，都有可能是影響未來的線索。<br><br>別放過任何小細節囉，麻煩你了。";
        } else if (this.gameType == "PushBottle") {
          return "一起來陪鬱悶的陽子喝酒玩遊戲吧。<br><br>請控制你的力道，把酒杯推到適當的位置。只有分數累積到「<span style='color:#64D8AE;'>斷片</span>」，<br>陽子才能暫時忘記她的煩惱。<br>可別把酒杯打翻了，要是玻璃碎了、發生什麼事情就麻煩了。<br><br>來吧，和陽子一起喝到斷片！";
        } else if (this.gameType == "Finding") {
          return "在這一片雜亂之中，請試著找出可能會有幫助的東西吧。<br>每一個物品背後的故事，都有可能是影響未來的線索。<br><br>別放過任何小細節囉，麻煩你了。";
        } else if (this.gameType == "Horror") {
          return "森林好暗啊，真的看得到這裡面有什麼嗎？<br>請你看看櫃子裡到底放了什麼東西吧。<br>節目組需要你找到他們要的東西——兩隻死老鼠<br><br><br>小心，未知之所以可怕是有原因的⋯⋯";
        }
      } else if (this.PopStep == "gameEnd") {
        return "遊戲結束";
      }
    },
    spaceStatus() {
      if (this.gameState == 0) {
        if (
          this.gameType == "CleanStains" ||
          this.gameType == "DonateGame" ||
          this.gameType == "PushBottle"
        )
          return true;
      } else {
        return false;
      }
    },
    gameTutorial() {
      if (this.gameState == 1) {
        if (
          this.gameType == "CleanStains" ||
          this.gameType == "DonateGame" ||
          this.gameType == "PushBottle"
        )
          return true;
        else return false;
      } else if (this.gameState == 0) {
        if (
          this.gameType == "CardGame" ||
          this.gameType == "Finding" ||
          this.gameType == "Horror"
        )
          return true;
        else return false;
      } else if ((this.PopStep = "story")) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    initLottery() {
      this.lotterystep = 1;
    },
    submit() {
      let vm = this;
      switch (this.PopStep) {
        case "story":
          this.$emit("chooseCharacter");
          break;
        case "character":
          this.$emit("checkCharacter", this.characterSelect);
          break;
        case "lottery":
          if (this.lotterystep != 3) {
            if (this.lotterystep == 2) {
              this.submitStatus = true;
              if (this.success) {
                vm.lotterystep++;
              }
            } else {
              vm.lotterystep++;
            }
          } else {
            this.$emit("closePop");
            vm.lotterystep = null;
            vm.success = false;
            vm.submitStatus = false;
          }
          break;
        case "game":
          //if(this.gameType=='CardGame'){
          this.$emit("closePop");
          //}
          break;
        default:
          this.$emit("closePop");
          break;
      }
    },
    // tapping(){
    //   this.$emit('tapping');
    // },
    closePop() {
      this.$emit("closePop");
    },
    setSelect(idx) {
      this.characterSelect = idx;
    },
    nextState() {
      console.log("nextState");
      this.$emit("nextState");
    },
    again() {
      this.$emit("again");
    },
    back() {
      this.$emit("back");
    },
    lotterySuccess() {
      this.success = true;
      this.submit();
      this.$emit("lotterySubmit")
    },
    lotteryFailed() {
      this.submitStatus = false;
    },
    tapping() {
      let vm = this;
      if (vm.PopStep == "lottery") {
        if (vm.lotterystep == 1) {
          vm.lotterystep++;
        } else if (vm.lotterystep == 2) {
        } else {
          this.$emit("closePop");
          vm.lotterystep = null;
          vm.success = false;
          vm.submitStatus = false;
        }
      } else if (vm.gameState == 0) {
        if (
          vm.gameType == "CleanStains" ||
          vm.gameType == "DonateGame" ||
          vm.gameType == "PushBottle"
        ) {
          vm.nextState();
        }
      }
    },
    goToUrl(type) {
      if(type=='live')
        window.open("https://ntch-lunatictown.com/programs");
      else
        window.open("https://lunatic-town.theater/");
      //this.closePop();
    },
  },
  watch: {
    tapStatus() {
      if (tapStatus) {
        this.tapping();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/scss/_variable.scss";
@import "@/assets/scss/_media.scss";

.pop {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9999999;
  height: 100%;
  .blackBg.game {
    cursor: default !important;
  }
  .main {
    position: absolute;
    //width: 50%;
    max-width: 894px;
    width: 720px;
    @include abscenter;
    @include multiBreakpoint(tabletS, phone) {
      width: 450px;
    }
    h1 {
      position: absolute;
      top: 3.5%;
      text-align: center;
      color: #fff;
      letter-spacing: 0.1em;
      @include absXcenter;
    }
    &.game {
      top: 50%;
      cursor: default !important;
    }
    p {
      position: absolute;
      top: 27%;
      letter-spacing: 0.1em;
      padding-top: 5px;
      @include absXcenter;
      width: 77%;
      text-align: center;
      height: 44%;
      overflow: auto;
      font-size: 21px;

      &::-webkit-scrollbar {
        width: 5px;
        // margin-right: 5px;
      }
      &::-webkit-scrollbar-track {
        //margin:5px 5px 5px 0;
        //width: 5px;
        background: transparent;
        border: none;
      }
      &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 100px;
        border-radius: 100px;
        background: #adf3f9;
        //width: 10px;
      }
    }
    span {
      color: #ffb359;
    }
    .btnWrap {
      display: flex;
      position: absolute;
      @include absXcenter;
      bottom: 9%;
      justify-content: center;
      .btnL,
      .btnR {
        cursor: pointer;
        // width: 40%;
        width: 80%;
        margin: 0 2%;
      }
    }
    .cat {
      position: absolute;
      right: 10%;
      bottom: 7%;
      width: 7%;
      max-width: 64px;
    }
    .gameImg {
      width: 70%;
      @include absXcenter;
      position: absolute;
      top: 20%;
      &.pushBottle {
        img {
          width: 120%;
          position: absolute;
          left: 50%;
          margin-top: 3%;
          transform: translateX(-50%);
        }
      }
    }
    .btn_exit {
      position: absolute;
      left: -6%;
      top: 0;
      width: 6%;
    }
    .btn_space {
      position: absolute;
      bottom: 9%;
      width: 5%;
      @include absXcenter;
      left: 46%;
      /* animation 參數設定 */
      animation-name: bouse; /*動畫名稱，需與 keyframe 名稱對應*/
      animation-duration: 0.5s; /*動畫持續時間，單位為秒*/
      animation-delay: 0.5s; /*動畫延遲開始時間*/
      animation-iteration-count: infinite; /*動畫次數，infinite 為無限次*/
      animation-direction: alternate;
      transition: ease-in-out;
      &.mobile {
        display: none !important;
        @include multiBreakpoint(tabletS, phone) {
          display: block !important;
        }
      }
      &.desktop {
        @include multiBreakpoint(tabletS, phone) {
          display: none !important;
        }
      }
    }
    @keyframes bouse {
      0% {
        transform: translateY(0);
      }
      100% {
        transform: translateY(-10px);
      }
    }
    .character,
    .faq,
    .lotteryContent {
      position: absolute;
      top: 24%;
      @include absXcenter;
    }
    .faq {
      height: 64%;
      overflow-y: auto;
    }
    .lotteryContent {
      top: 25%;
      width: 74%;
    }
    .controlHelp {
      top: 24%;
      position: absolute;
    }
    .button {
      position: absolute;
      bottom: 12%;
      width: 19%;
      @include absXcenter;
    }
    &.story,
    &.collectionend {
      h1 {
        //font-size: 2.4vw;
        font-size: 35px;
        @include multiBreakpoint(tabletS, phone) {
          font-size: 22px;
        }
      }
      p {
        //font-size: 1.45vw;
        font-size: 21px;
        @include multiBreakpoint(tabletS, phone) {
          font-size: 15px;
        }
      }
    }
    &.character,
    &.faq,
    &.controlhelp,
    &.lottery,
    &.lotteryEnd,
    &.game {
      h1 {
        font-size: 45px;
        top: 6.5%;
        width: 100%;
        padding-left: 4%;
        @include multiBreakpoint(tabletS, phone) {
          font-size: 30px;
          top: 6%;
        }
        &.lotteryH1 {
          font-size: 35px;
          top: 3.5%;
          text-align: center;
          color: #fff;
          letter-spacing: 0.1em;
          @include multiBreakpoint(tabletS, phone) {
            font-size: 22px;
          }
        }
      }
      .button {
        bottom: 9%;
      }
    }
    &.lotteryEnd,
    &.game {
      p {
        @include multiBreakpoint(tabletS, phone) {
          font-size: 15px;
        }
      }
    }
    &.lottery {
      .button {
        width: 21%;
      }
    }
    &.story1030 {
      h1 {
        //font-size: 2.4vw;
        font-size: 35px;
        width: 57%;
        left: 50%;
        top: 8%;
        transform: translateX(-50%) rotate(-1deg);
        @include multiBreakpoint(tabletS) {
          font-size: 25px;
        }
      }
      p {
        //font-size: 1.45vw;
        font-size: 21px;
        width: 54%;
        transform: translateX(-50%) rotate(-2deg);
        line-height: 1.1;
        @include multiBreakpoint(tabletS, phone) {
          font-size: 15px;
        }
      }
    }
    &.lottery {
      ::v-deep .step1 p,
      ::v-deep .step3 p {
        font-size: 21px;
        text-align: center;
        @include multiBreakpoint(tabletS, phone) {
          font-size: 15px;
        }
        span {
          color: #ffb359;
        }
      }
    }
  }
}
</style>