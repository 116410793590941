<template>
  <div class="cleanstains">
    <canvas id="myCanvas" ref="myCanvas"></canvas>
    <PopUp
      v-if="pop.status"
      :PopStep="pop.PopStep"
      @closePop="closePop"
      :gameType="pop.gameType"
      @nextState="nextState"
      :gameState="pop.gameState"
      @back="back"
      @again="again"
      @tapping="tapping"
    />
  </div>
</template>
<script>
import PopUp from "@/components/PopUp.vue";
import * as PIXI from "pixi.js";
import { gsap } from "gsap";
import PixiPlugin from "gsap/PixiPlugin";
import { sound } from "@pixi/sound";
export default {
  data: () => ({
    app: null,
    brush: null,
    dragging: false,
    loader: null,
    centerContainer: null,
    imageToReveal: null,
    renderTextureSprite: null,
    renderTexture: null,
    bubble: null,
    bubbles: [],
    pop: {
      PopStep: "game",
      gameType: "CleanStains",
      status: true,
      gameState: 0,
    },
    ratio: null,
    windowRatio: null,
    step: {
      num: 0,
    },
  }),
  components: { PopUp },
  props: {
    tapStatus: {
      default: false,
    },
  },
  mounted() {
    this.setMediaLoad();
  },
  methods: {
    init() {
      this.setCanvas();
      this.setup();
      this.setClose();
    },
    closePop() {
      this.pop.status = false;
    },
    setMediaLoad() {
      this.loader = new PIXI.Loader();
      this.loader.reset();
      this.loader
        .add([
          { name: "bg", url: "./game/cleanstains/tGIn4hK.jpg" },
          { name: "toplayer", url: "./game/cleanstains/topplayer.png" },
          { name: "bottomlayer", url: "./game/cleanstains/bottomplayer.png" },
          { name: "mask", url: "./game/cleanstains/mask.png" },
          { name: "hand", url: "./game/cleanstains/hand.png" },
          { name: "blink", url: "./game/cleanstains/blink.png" },
          { name: "bubble", url: "./game/cleanstains/bubble/bubble.json" },
          { name: "music", url: "./game/cleanstains/music.mp3" },
          { name: "close", url: "./game/close.png" },
        ])
        .load(this.init);
    },
    setCanvas() {
      let vm = this;
      let myCanvas = this.$refs.myCanvas;
      this.app = new PIXI.Application({
        width: 1920,
        height: 1080,
        antialias: true,
        transparent: false,
        resolution: 2,
        view: myCanvas,
      });
      this.app.renderer.view.style.position = "absolute";
      this.app.renderer.view.style.display = "block";
      this.app.renderer.autoResize = true;
      this.app.stage.interactive = true;
      let screen = this.app.renderer.screen;
      this.app.renderer.resize(window.innerWidth, window.innerHeight);
      this.initRotation = "L";
      this.app.stage.interactive = true;
      if (window.matchMedia("(orientation: portrait)").matches) {
        this.app.renderer.resize(window.innerHeight, window.innerWidth);
        this.windowWidth = window.innerHeight;
        this.windowHeight = window.innerWidth;
      } else {
        this.app.renderer.resize(window.innerWidth, window.innerHeight);
        this.windowWidth = window.innerWidth;
        this.windowHeight = window.innerHeight;
      }
      sound.add("my-sound", "/game/cleanstains/music.mp3");
      sound.play("my-sound", { loop: true });
      // if(window.innerWidth<window.innerHeight){
      //     this.app.stage.position.set(screen.width/2, screen.height/2); // center of the screen
      //     this.app.stage.rotation = Math.PI/2;
      //     this.app.stage.pivot.set(screen.width/2, screen.height/2);
      //     this.initRotation = 'O';
      // }
      // window.onresize = function (event){
      //     vm.app.renderer.resize(window.innerWidth, window.innerHeight);
      //     screen = vm.app.renderer.screen;
      //     if(vm.initRotation == 'O'){
      //         if(window.innerWidth>window.innerHeight){
      //             vm.app.stage.pivot.set(screen.height/2,screen.width/2);
      //             vm.app.stage.position.set(screen.width/2, screen.height/2);
      //             vm.app.stage.rotation = 0;
      //         }else{
      //             vm.app.stage.position.set(screen.width/2, screen.height/2);
      //             vm.app.stage.pivot.set(screen.width/2, screen.height/2);
      //             vm.app.stage.rotation = Math.PI/2;
      //         }
      //         vm.initRotation = 'L';
      //     }else{
      //         if(window.innerWidth>window.innerHeight){
      //             vm.app.stage.position.set(0,0);
      //             vm.app.stage.pivot.set(0, 0);
      //             vm.app.stage.rotation = 0;
      //         }else{
      //             vm.app.stage.position.set(screen.width/2, screen.height/2);
      //             vm.app.stage.pivot.set(screen.height/2,screen.width/2);
      //             vm.app.stage.rotation = Math.PI/2;
      //         }
      //         vm.initRotation = 'O'
      //     }
      //     // 將畫面的正中間放在 app.renderer 一半寬高的位置
      //     // this.app.stage.x = app.renderer.width * 0.5;
      //     // this.app.stage.y = app.renderer.height * 0.5;
      // };
    },
    setBrush() {
      this.brush = new PIXI.Graphics();
      this.brush.beginFill(0xffffff);
      this.brush.drawRoundedRect(
        0,
        0,
        this.imageToReveal.width * 0.05,
        this.imageToReveal.height * 0.09,
        3
      );
      this.brush.endFill();
    },
    setBlink() {
      let vm = this;
      if (this.step.num != 1) return;
      let scaleRatioX, scaleRatioY;
      for (let index = 0; index < 6; index++) {
        let blink = new PIXI.Sprite(this.loader.resources["blink"].texture);
        blink.width = blink.height = this.imageToReveal.height * 0.075;
        scaleRatioX = blink.scale.x;
        scaleRatioY = blink.scale.y;
        blink.scale.x = blink.scale.y = 0;
        blink.anchor.x = 0.5;
        blink.anchor.y = 0.5;
        let textArray = [1, -1];
        let randomNumber = Math.floor(Math.random() * textArray.length);
        let textArray1 = [1, -1];
        let randomNumber1 = Math.floor(Math.random() * textArray1.length);
        let randomY =
          this.renderTextureSprite.y +
          textArray[randomNumber] *
            this.getRandom(0, this.renderTextureSprite.height / 2) +
          this.getRandom(-20, 20);
        let randomX =
          textArray1[randomNumber1] *
            this.getRandom(0, this.renderTextureSprite.width / 2) +
          this.getRandom(-20, 20);
        blink.x = randomX;
        blink.y = randomY;
        blink.alpha = 0;
        let tl = gsap.timeline({ default: { ease: "power2.out" } });
        tl.to(blink, {
          duration: this.getRandom(5, 8) / 10,
          pixi: { scaleX: scaleRatioX, scaleY: scaleRatioY, alpha: 1 },
          repeat: -1,
          yoyo: true,
        }).to(vm.step, {
          duration: 3,
          num: 2,
          onComplete: () => {
            vm.pop = {
              PopStep: "gameEnd",
              gameType: "CleanStains",
              status: true,
            };
            blink.destroy();
          },
        });
        this.centerContainer.addChild(blink);
      }
    },
    getRandom(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    nextState() {
      console.log("nextState");
      this.pop.gameState++;
    },
    setup() {
      const defaultIcon = "url('./game/cleanstains/hand.png'),auto";
      this.app.renderer.plugins.interaction.cursorStyles.default = defaultIcon;
      this.centerContainer = new PIXI.Container();
      this.app.stage.addChild(this.centerContainer);
      this.centerContainer.position.x = this.app.screen.width / 2;
      this.centerContainer.position.y = this.app.screen.height / 2;

      let onTopOfMask = new PIXI.Sprite(
        this.loader.resources["toplayer"].texture
      );
      this.centerContainer.addChild(onTopOfMask);
      onTopOfMask.width = (this.app.screen.height / 9) * 16;
      onTopOfMask.height = this.app.screen.height;
      onTopOfMask.anchor.x = 0.5;
      onTopOfMask.anchor.y = 0.5;
      this.setResize(onTopOfMask);

      this.imageToReveal = new PIXI.Sprite(
        this.loader.resources["bottomlayer"].texture
      );
      this.centerContainer.addChild(this.imageToReveal);
      this.imageToReveal.width = onTopOfMask.width;
      this.imageToReveal.height = onTopOfMask.height;
      this.imageToReveal.anchor.x = 0.5;
      this.imageToReveal.anchor.y = 0.5;

      this.renderTexture = PIXI.RenderTexture.create(
        onTopOfMask.width * 0.82,
        onTopOfMask.height * 0.55
      );

      // let renderTexture = PIXI.RenderTexture.fromImage(resources["mask"].texture);

      this.renderTextureSprite = new PIXI.Sprite(this.renderTexture);
      this.centerContainer.addChild(this.renderTextureSprite);
      this.renderTextureSprite.anchor.x = 0.5;
      this.renderTextureSprite.anchor.y = 0.5;
      this.renderTextureSprite.y = (-1 * onTopOfMask.height * 0.36) / 2;

      this.imageToReveal.mask = this.renderTextureSprite;

      this.centerContainer.interactive = true;
      this.centerContainer.on("pointerdown", this.pointerDown);
      this.centerContainer.on("pointerup", this.pointerUp);
      this.centerContainer.on("pointermove", this.pointerMove);

      this.setBrush();
    },
    setResize(item) {
      this.ratio = item.height / item.width;
      this.windowRatio = window.innerHeight / window.innerWidth;

      if (this.ratio < this.windowRatio) {
        item.width = window.innerWidth;
        item.height = item.width * this.ratio;
      } else {
        item.height = window.innerHeight;
        item.width = item.height / this.ratio;
      }
    },
    setBubble(x, y) {
      let sheet = this.loader.resources.bubble.spritesheet;
      let bubble = new PIXI.AnimatedSprite(sheet.animations[`bubble`]);
      let globalTransform = this.imageToReveal.toGlobal(
        this.app.stage.position
      );
      bubble.height = bubble.width = this.imageToReveal.width * 0.05;
      bubble.play();
      bubble.animationSpeed = 0.1;
      // bubble.x = x + globalTransform.x - this.imageToReveal.width/2;
      // bubble.y = y + globalTransform.y - this.imageToReveal.height/2;
      if (window.innerHeight > window.innerWidth) {
        bubble.x = (-1 * this.imageToReveal.width) / 2 + y;
        bubble.y = this.imageToReveal.height / 2 - x;
      } else {
        bubble.x = (-1 * this.imageToReveal.width) / 2 + x;
        bubble.y = (-1 * this.imageToReveal.height) / 2 + y;
      }
      bubble.anchor.x = 0.5;
      bubble.anchor.y = 0.5;
      let timer = window.setTimeout(() => {
        bubble.destroy();
        window.clearTimeout(timer);
      }, 1000);
      //this.app.stage.addChild(bubble);
      this.centerContainer.addChild(bubble);
    },
    pointerMove(event) {
      if (this.dragging) {
        this.renderTextureSprite.toLocal(
          event.data.global,
          undefined,
          this.brush
        );
        this.brush.x +=
          this.renderTextureSprite.anchor.x * this.renderTexture.width -
          (this.imageToReveal.width * 0.05) / 2;
        this.brush.y +=
          this.renderTextureSprite.anchor.y * this.renderTexture.height -
          (this.imageToReveal.height * 0.09) / 2;

        //brush.position.copy(event.data.global);
        this.debounce(this.setBubble(event.data.global.x, event.data.global.y));
        let pixels = this.app.renderer.extract.pixels(this.renderTexture);
        let count = 0;
        for (let i = 0, len = pixels.length; i < len; i += 4) {
          if (pixels[i] === 255) {
            ++count;
          }
        }

        //let progress = (200 * count / (this.renderTexture.width * this.renderTexture.height)).toFixed(2);
        let progress = (
          count /
          (this.renderTexture.width * this.renderTexture.height)
        ).toFixed(2);
        //console.log('Progress: ' + progress + '%');
        if (progress > 0.75) {
          this.step.num++;
          this.setBlink();
        }
        this.app.renderer.render(
          this.brush,
          this.renderTexture,
          false,
          null,
          false
        );
      }
    },
    debounce(func, delay = 500) {
      let timer = null;

      return () => {
        let context = this;
        let args = arguments;

        clearTimeout(timer);
        timer = setTimeout(() => {
          func.apply(context, args);
        }, delay);
      };
    },
    pointerDown(event) {
      if (this.step.num == 0) {
        this.dragging = true;
        this.pointerMove(event);
      }
    },
    pointerUp(event) {
      this.dragging = false;
    },
    close() {
      this.close = new PIXI.Sprite(this.loader.resources["close"].texture);
      this.close.x = 30;
      this.close.y = 50;
      this.close.interactive = true;
      this.close.on("pointerdown", this.back);
      this.centerContainer.addChild(this.close);
    },
    setClose() {
      let vm = this;
      this.close = new PIXI.Sprite(this.loader.resources["close"].texture);
      this.close.x = 30 - this.imageToReveal.width/2;
      this.close.y = 30 - this.imageToReveal.height/2;
       this.close.height = this.close.width = this.imageToReveal.width*0.03;
      this.close.interactive = true;
      this.close.on("pointerdown", function (e) {
        sound.stop("my-sound");
        vm.$emit("back", 6); // 6: Failed
      });
      this.centerContainer.addChild(this.close);
    },
    back() {
      sound.stop("my-sound");
      this.$emit("back", 5); //5: CleanStains
    },
    again() {
      this.pop = {
        PopStep: "game",
        gameType: "CleanStains",
        status: false,
        gameState: 0,
      };
      this.step.num = 0;
      this.app.stage.removeChild();
      this.setup();
    },
    tapping() {
      if (this.pop.gameState == 0) {
        this.nextState();
        this.$emit("setTapHide");
      }
    },
  },
  pointerDown(event) {
    if (this.step.num == 0) {
      this.dragging = true;
      this.pointerMove(event);
    }
  },
  // watch:{
  //     tapStatus(){
  //         if(this.tapStatus){
  //             if(this.pop.gameState==0){
  //                 this.nextState();
  //                 this.$emit('setTapHide');
  //             }
  //         }
  //     }
  // }
};
</script>
<style lang="scss" scoped>
.cleanstains {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  canvas {
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
    // height:100%;
    // width: 100%;
    // @media screen and (min-width: 320px) and (max-width: 767px) and (orientation:portrait) {
    //     transform: rotate(-90deg);
    //     height: 100vh;
    //     width: 100vw;
    //     bottom: -100vh;
    //     transform-origin: 0px 0px;
    // }
  }
}
</style>
